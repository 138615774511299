import React from 'react'

import Date from '../../Date'
import Link from '../../Link'
import Image from '../../Image'

import s from './styles.module.css'
import { ROUTE_ANNOUNCEMENTS } from '../../../../consts'
import { Announcement as AnnouncementProps } from '../../../../providers/SiteProvider/types'

import { buildPath, getWordsFromString, stripTags } from '../../../../helpers'
import Typography from '../../Typography'

/**
 * Single announcement component
 * @param props 
 * @returns 
 */
const Announcement = (props: AnnouncementProps) => {
  const { publishedDate, coverImage, title, slug, content } = props
  const { alt = "", src } = coverImage

  // TODO:: Link to read more needs corrected
  return (
    <article className={s.announcement}>
      <div className={s.wrapper}>
        <Typography
          as="span"
          variant="secondary"
          className={s.posted_at}>
          <Date dateString={publishedDate} />
        </Typography>
        <Link
          href={buildPath(ROUTE_ANNOUNCEMENTS, slug)}>
          <div className={s.header}>
            <Typography
              as="h5"
              className={s.title}>
              {title}
            </Typography>
          </div>
          <Typography className={s.description} as="p">
            {getWordsFromString(stripTags(content), 20, true)}
          </Typography>
        </Link>
      </div>
    </article >
  )
}

export default Announcement