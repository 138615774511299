import React, { PropsWithChildren } from 'react'

import s from './styles.module.css'

/**
 * List item
 * @param props 
 * @returns 
 */
const ListItem = (props: PropsWithChildren) => {
  const { children } = props

  return (
    <article className={s.list_item} >
      {children}
    </article >
  )
}

export default ListItem

