const isProd = process.env.NEXT_PUBLIC_ENV === 'production'
const isStaging = process.env.NEXT_PUBLIC_ENV === 'staging'
const isNetworkMainnet = (process.env.NEXT_PUBLIC_CARDANO_NETWORK_TYPE ?? 'preprod') === 'mainnet'

const getCspString = (nonce: string): string => {
    const csp = {
        'default-src': ["'none'"],
        // NextJS requires "unsafe-eval" in dev (faster source maps)
        'script-src': [
            "'self'",
            "'unsafe-inline'",
            'https://*.facebook.net',
            'https://*.google-analytics.com',
            'https://*.googletagmanager.com',
            'https://*.hcaptcha.com',
            'https://hcaptcha.com',
            // Twitter pixel
            'https://static.ads-twitter.com',
            'https://www.youtube.com',
            'https://js.stripe.com',
            '*.cloudflareinsights.com',
            isProd || isStaging ? '' : "'unsafe-eval'",
            isProd || isStaging ? `'nonce-${nonce}'` : '',
            isProd || isStaging ? `'strict-dynamic'` : '',
        ],
        'style-src': [
            "'self'",
            "'unsafe-inline'",
            'https://*.hcaptcha.com',
            'https://hcaptcha.com',
        ],
        'img-src': [
            "'self'",
            'https://cdn.filestackcontent.com',
            'https://i.ytimg.com',
            'https://*.facebook.com',
            'https://*.google-analytics.com',
            'https://*.googletagmanager.com',
            'https://*.walletconnect.org',
            'https://*.walletconnect.com',
            // Twitter pixel
            'https://t.co',
            'https://analytics.twitter.com',
            // This is to allow test NFT images to load
            !isProd ? 'https://ipfs.io' : '',
            'data:',
        ],
        'connect-src': [
            "'self'",
            // Launcher custom protocol
            'cornucopias:',
            'https://*.rum-ingress-coralogix.com',
            'https://api.coingecko.com',
            'https://open-api.dextools.io',
            'https://js.cexplorer.io',
            'https://api.hsforms.com',
            'https://*.s3.amazonaws.com',
            'https://*.filestackapi.com',
            'wss://www.walletlink.org/rpc',
            'wss://*.walletconnect.com',
            'https://*.walletconnect.com',
            'wss://*.walletconnect.org',
            'https://*.walletconnect.org',
            isNetworkMainnet ? 'https://api.koios.rest' : 'https://preprod.koios.rest',
            'https://*.google-analytics.com',
            'https://*.googletagmanager.com',
            isProd ? 'https://*.cornucopiasweb.io' : 'https://*.cornucopiasweb.dev',
            // Add staging if we are on staging
            !isProd ? 'https://*.cornucopias.dev' : '',
            'https://*.cornucopias.io',
            'https://*.hcaptcha.com',
            'https://hcaptcha.com',
            'https://*.facebook.com',
            '*.cloudflareinsights.com',
        ],
        'frame-ancestors': [
            isProd ? 'https://*.cornucopias.io' : 'https://*.cornucopias.dev',
            'https://verify.walletconnect.com',
        ],
        'frame-src': [
            "'self'",
            'https://js.stripe.com',
            'https://*.hcaptcha.com',
            'https://hcaptcha.com',
            'https://www.youtube.com',
            'https://youtube.com',
            'https://*.walletconnect.org',
            'https://*.walletconnect.com',
            'https://*.facebook.com',
        ],
        'media-src': ["'self'", 'https://cdn.filestackcontent.com', 'https://www.youtube.com'],
        'font-src': ["'self'"],
        'form-action': ["'self'", 'https://*.facebook.com'],
        'object-src': ["'none'"],
        'base-uri': ["'self'"],
    }

    let cspString = ''

    Object.entries(csp).forEach(([directive, sources]) => {
        if (sources.length > 0) {
            const sourceString = sources.join(' ')
            cspString += `${directive} ${sourceString}; `
        }
    })

    return cspString
}

export default getCspString
