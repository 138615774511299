// eslint-disable @typescript-eslint/no-unused-vars
// eslint-disable no-unused-vars
import { useRef, useReducer, useCallback } from 'react'
import { HubSpotFormSuccessType } from './types'

const HUBSPOT_API = 'https://api.hsforms.com/submissions/v3/integration/submit'

interface State<T> {
    data?: HubSpotFormSuccessType
    error?: Error
}

type Action<T> =
    | { type: 'loading' }
    | { type: 'fetched'; payload: HubSpotFormSuccessType }
    | { type: 'error'; payload: Error }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatKeyValuePair = (data: Record<string, any>) =>
    Object.keys(data).map((key) => {
        const value = data[key]

        return {
            objectTypeId: '0-1',
            name: key,
            value,
        }
    })

const useHubSpotForm = <T = unknown>(portalId: string, formId: string, honeypotKey?: string) => {
    const url = `${HUBSPOT_API}/${portalId}/${formId}`

    const cancelRequest = useRef<boolean>(false)

    const initialState: State<T> = {
        error: undefined,
        data: undefined,
    }

    const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
        switch (action.type) {
            case 'loading':
                return { ...initialState }
            case 'fetched':
                return { ...initialState, data: action.payload }
            case 'error':
                return { ...initialState, error: action.payload }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(fetchReducer, initialState)

    const fetchData = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (formData: Record<string, any>) => {
            dispatch({ type: 'loading' })

            const finalData = {
                submittedAt: Date.now(),
                fields: formatKeyValuePair(formData),
            }

            const options = {
                method: 'post',
                body: JSON.stringify(finalData),
                headers: {
                    'Content-Type': 'application/json',
                },
            } as RequestInit

            try {
                const response = await fetch(url, options)
                if (!response.ok) {
                    throw new Error(response.statusText)
                }

                const data = (await response.json()) as HubSpotFormSuccessType

                if (cancelRequest.current) return

                dispatch({ type: 'fetched', payload: data })
            } catch (error) {
                if (cancelRequest.current) return

                dispatch({ type: 'error', payload: error as Error })
            }
        },
        [url],
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = (data: Record<string, any>) => {
        // If no honeypot fetch, otherwise check it's value
        if (!honeypotKey || (honeypotKey && data[honeypotKey] !== true)) fetchData(data)
    }

    return {
        onSubmit,
        ...state,
    }
}

export default useHubSpotForm
