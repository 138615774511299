import { AxiosResponse } from 'axios'

const handleError = (response: AxiosResponse) => {
    const { data } = response

    if (typeof data === 'string' && (data as string).includes('Malwarebytes Browser Guard Logo')) {
        throw new Error(
            "Malwarebytes has blocked 'Koios' a 3rd party service. Please whitelist this service to fix this issue.",
        )
    }
}

export default handleError
