import React from 'react'
import cn from 'classnames'

import { useVariant } from '../../../hooks'
import { PillProps } from './types'

import s from "./styles.module.css"

const Pill = (props: PillProps) => {
  const { children, variant, filled, outline, hover = false, className } = props

  const variantClass = useVariant({
    variant,
    outline,
    filled,
    hover
  })

  return (
    <span className={cn(s.pill, variantClass, className)}>{children}</span>
  )
}

export default Pill