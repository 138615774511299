import React, { ElementType } from 'react'
import { motion } from 'framer-motion'
import cx from 'classnames'

import { useVariant } from '../../../hooks'
import Loader from '../Loader'

import { StyledButtonProps } from './types'
import s from './styles.module.css'

/**
 * Button styled with corner cuts
 * @param props 
 * @returns 
 */
const StyledButton = <T extends ElementType = "button">(props: StyledButtonProps<T>) => {
  const { as, icon, children, className, disabled, color = "default", variant, loading, size = "md", ...buttonProps } = props

  const Component = motion(as || "button")
  const type = variant || "filled"

  // First get background for pseudo
  const variantClassBefore = useVariant({
    variant: color,
    filled: true,
    hover: true,
    pseudoElement: "before"
  })

  // Then get color for element, this is overriden when filled by CSS
  const variantClass = useVariant({
    variant: color,
    color: true,
  })

  const classes = cx(s.button, s[type], s[color], s[size], variantClass, variantClassBefore, disabled && s.disabled, className)
  let content = children

  if (loading) {
    content = (
      <div className="flex justify-center w-full">
        <Loader className="w-10" />
      </div>)
  }
  else if (icon) {
    content = (
      <div className={s.button_contents}>
        <span className={s.button_icon}>{icon}</span>
        {children}
      </div>
    )
  }

  return (
    <Component
      whileHover={!disabled && { scale: 1.02 }}
      whileTap={{ scale: 1 }}
      className={classes}
      disabled={disabled}
      {...buttonProps}>
      {content}
    </Component>
  )
}

export default StyledButton
