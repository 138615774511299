import React from 'react'
import cn from 'classnames'

import { PanelProps } from './types'
import s from './styles.module.css'

/**
 * Panel provides a white background with opacity
 * @param props 
 * @returns 
 */
function Panel(props: PanelProps) {
  const { children, className, rounded = "lg" } = props

  return (
    <div className={cn(s.panel, s[`rounding_${rounded}`], className)}>
      {children}
    </div>
  )
}

export default Panel