import { useCallback, useState } from 'react'

type StoredValueType<T> = {
    value: T
    expiry?: number | null
}

/**
 * Save value to state and local storage
 * @param key
 * @param initialValue
 * @param ttl seconds
 * @param setExpiryOnFirstUpdate boolean
 * @param updateExpiryOnChange boolean
 * @param resetOnExpireOnChange boolean
 * @param resetOnExpireOnLoad boolean
 * @returns Array - value, setValue, expiry timestamp, reset function, has expired
 */
function useLocalStorage<T>(
    key: string,
    initialValue: T,
    ttl?: number,
    setExpiryOnFirstUpdate = false,
    updateExpiryOnChange = false,
    resetOnExpireOnChange = false,
    resetOnExpireOnLoad = true,
) {
    const createValue = useCallback(
        (value: T, expiry?: number | null) => ({
            value,
            expiry,
        }),
        [],
    )

    const hasExpired = (storedItem: StoredValueType<T>) => {
        if (!storedItem.expiry) return true

        return new Date().getTime() > storedItem.expiry
    }

    const getExpiryTime = (ttlExpiry: number) => new Date().getTime() + ttlExpiry * 1000

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<StoredValueType<T>>(() => {
        const expiry = !setExpiryOnFirstUpdate && ttl ? getExpiryTime(ttl) : undefined

        if (typeof window === 'undefined') {
            return createValue(initialValue, expiry)
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key)

            // If no value exists return default
            if (!item) return createValue(initialValue, expiry)

            const storedItem = JSON.parse(item)

            // If it should expire check it
            if (ttl && hasExpired(storedItem) && resetOnExpireOnLoad) {
                window.localStorage.removeItem(key)

                return createValue(initialValue, expiry)
            }

            return storedItem
        } catch (error) {
            // If error also return initialValue
            // console.log(error)

            return createValue(initialValue, expiry)
        }
    })

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useCallback(
        (value: T | ((val: T) => T), refreshExpiry = false) => {
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore = value instanceof Function ? value(storedValue.value) : value
                let expiry = null

                if (ttl) {
                    if (
                        (setExpiryOnFirstUpdate && !storedValue.expiry) ||
                        updateExpiryOnChange ||
                        (resetOnExpireOnChange && hasExpired(storedValue)) ||
                        refreshExpiry
                    ) {
                        // Update expiry time
                        expiry = getExpiryTime(ttl)
                    } else {
                        // Retain same expiry time
                        expiry = storedValue.expiry
                    }
                }

                const itemToStore = createValue(valueToStore, expiry)

                // Save state
                setStoredValue(itemToStore)

                // Save to local storage
                if (typeof window !== 'undefined') {
                    window.localStorage.setItem(key, JSON.stringify(itemToStore))
                }
            } catch (error) {
                // A more advanced implementation would handle the error case
                // console.log(error)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [createValue, key, storedValue?.value],
    )

    const reset = useCallback(
        (value?: T, resetState = true) => {
            // Wipe localstorage
            window.localStorage.removeItem(key)
            // Optional wip state as well
            if (resetState) {
                const expiry = !setExpiryOnFirstUpdate && ttl ? getExpiryTime(ttl) : undefined
                setStoredValue(createValue(value || initialValue, expiry))
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [key, setExpiryOnFirstUpdate, ttl, initialValue],
    )

    return [
        storedValue.value,
        setValue,
        storedValue.expiry,
        reset,
        hasExpired(storedValue),
    ] as const
}

export default useLocalStorage
