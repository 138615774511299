
import React from 'react'

import Typography from '@/components/ui/Typography'

import s from './styles.module.css'
import { CardBodyProps } from './types'

/**
 * Card body component
 * @param props 
 * @returns 
 */
function CardBody(props: CardBodyProps) {
  const { title, children } = props

  return (
    <div className={s.content}>
      {title &&
        <Typography as="h3" className={s.title}>
          {title}
        </Typography>
      }
      <div className={s.body}>
        {children}
      </div>
    </div>
  )

}

export default CardBody