import {
    API_GET_USER,
    API_MFA_ASSOCIATE,
    API_MFA_CONNECT,
    API_CONFIRM_REGISTER,
    API_USER_ATTRIBUTE_EMAIL,
    API_USER_ATTRIBUTE_EMAIL_CONFIRM,
    API_USER_ATTRIBUTE_PASSWORD,
    API_USER_ATTRIBUTE_USERNAME,
    API_USER_DISCORD_AUTHORIZE,
    API_USER_DISCORD_CONNECT,
    API_USER_DISCORD_DISCONNECT,
    API_USER_RECOVER_PASSWORD,
    API_USER_RECOVER_PASSWORD_CONFIRM,
    API_USER_UNVERIFY_WALLET,
    API_USER_BULK_UNVERIFY_WALLET,
    API_MFA_DISCONNECT,
    API_USER_RESEND_CONFIRMATION_CODE,
    API_CHECK_USERNAME,
    API_USER_ATTRIBUTE_AVATAR,
    API_GET_USER_ASSETS,
    API_GET_USER_WALLET_ASSETS,
    API_GET_NFT_METADATA_SEARCH,
    API_USER_ATTRIBUTE_EMAIL_CHANGE_CANCEL,
    API_USER_SYNC_ROLES,
    API_GET_USER_ADDRESSES_TO_SYNC,
    API_GET_USER_ASSIGNED_CUSTOM_TAGS,
    API_GET_USER_UNASSIGNED_CUSTOM_TAGS,
    API_ASSIGN_USER_PROFILE_CUSTOM_TAG,
    API_GET_USER_ASSIGNED_PROFILE_CUSTOM_TAG,
    API_REMOVE_USER_CUSTOM_TAG_ASSIGNMENT,
    API_GET_USER_COPI_BALANCE,
    API_USER_VERIFY_WALLET_CARDANO,
    API_USER_VERIFY_WALLET_EVM,
    API_USER_VERIFY_HARDWARE_WALLET_CARDANO,
    API_USER_GET_HARDWARE_WALLET_CARDANO_CHALLENGE,
    API_DELETE_USER,
    API_UNDELETE_USER,
} from 'consts/apiRoutes'

import {
    MfaAssoicateResponseType,
    PlayerUserType,
    MfaConnectRequestType,
    UserUpdateUsernameRequestType,
    UserUpdatePasswordRequestType,
    UserUpdateEmailRequestType,
    EmailConfirmRequestType,
    UserPasswordRecoveryRequestType,
    UserPasswordRecoveryResponseType,
    UserPasswordRecoveryConfirmRequestType,
    UserPasswordRecoveryConfirmResponseType,
    UserVerifyCardanoWalletRequestType,
    UserVerifyEvmWalletRequestType,
    UserUnverifyWalletResponseType,
    DiscordAuthorizeResponse,
    UserResendCodeRequestType,
    UserResendCodeResponseType,
    CheckUsernameResponseType,
    UserUpdateAvatarRequestType,
    UserBulkUnverifyWalletResponseType,
    NftMetadataSearchResponse,
    NftMetadataSearchRequestType,
    WalletAssetType,
    UserCancelEmailRequestType,
    PlayerUserCopiBalanceResponse,
    UserVerifyCardanoHardwareWalletRequestType,
    UserDeleteRequestType,
} from 'types/Api'

import {
    MarketplaceCustomTagAssignmentResponseType,
    MarketplaceCustomTagAssignmentsResponseType,
    MarketplaceCustomTagRemoveResponseType,
    MarketplaceCustomTagUnassignedResponseType,
} from 'types'

import HttpClientBase from './HttpClientBase'

class PlayerHttpClient extends HttpClientBase {
    static setAccessToken(accessToken?: string) {
        super.setAccessToken(accessToken)

        return this
    }

    static async CheckUsername(payload: string) {
        const apiUrl = `${API_CHECK_USERNAME}?username=${payload}`

        return this.request<CheckUsernameResponseType>({
            url: apiUrl,
            method: 'GET',
        })
    }

    static async AccountConfirm(payload: EmailConfirmRequestType) {
        return this.request<PlayerUserType>({
            url: API_CONFIRM_REGISTER,
            data: payload,
            method: 'POST',
        })
    }

    static async ResendUserConfirmationCode(payload: UserResendCodeRequestType) {
        return this.request<UserResendCodeResponseType>({
            url: API_USER_RESEND_CONFIRMATION_CODE,
            data: payload,
            method: 'POST',
        })
    }

    static async MfaAssociate(id: number) {
        this.requireAuth()

        return this.request<MfaAssoicateResponseType>({
            url: API_MFA_ASSOCIATE(id),
            method: 'PUT',
        })
    }

    static async MfaConnect(id: number, payload: MfaConnectRequestType) {
        this.requireAuth()

        return this.request<PlayerUserType>({
            url: API_MFA_CONNECT(id),
            data: payload,
            method: 'PUT',
        })
    }

    static async MfaDisconnect(id: number, password: string) {
        this.requireAuth()

        return this.request<PlayerUserType>({
            url: API_MFA_DISCONNECT(id),
            data: { password },
            method: 'PUT',
        })
    }

    static async UserPasswordRecovery(payload: UserPasswordRecoveryRequestType) {
        return this.request<UserPasswordRecoveryResponseType>({
            url: API_USER_RECOVER_PASSWORD,
            data: payload,
            method: 'POST',
        })
    }

    static async UserPasswordRecoveryConfirm(payload: UserPasswordRecoveryConfirmRequestType) {
        return this.request<UserPasswordRecoveryConfirmResponseType>({
            url: API_USER_RECOVER_PASSWORD_CONFIRM,
            data: payload,
            method: 'POST',
        })
    }

    static async UserUpdatePassword(id: number, payload: UserUpdatePasswordRequestType) {
        this.requireAuth()

        return this.request<PlayerUserType>({
            url: API_USER_ATTRIBUTE_PASSWORD(id),
            data: payload,
            method: 'PUT',
        })
    }

    static async UserSyncRoles(id: number) {
        this.requireAuth()

        return this.request<PlayerUserType>({
            url: API_USER_SYNC_ROLES(id),
            method: 'PUT',
        })
    }

    static async UserUpdateUsername(id: number, payload: UserUpdateUsernameRequestType) {
        this.requireAuth()

        return this.request<PlayerUserType>({
            url: API_USER_ATTRIBUTE_USERNAME(id),
            data: payload,
            method: 'PUT',
        })
    }

    static async UserUpdateEmail(id: number, payload: UserUpdateEmailRequestType) {
        this.requireAuth()

        return this.request<PlayerUserType>({
            url: API_USER_ATTRIBUTE_EMAIL(id),
            data: payload,
            method: 'PUT',
        })
    }

    static async UserCancelEmailChange(id: number, payload: UserCancelEmailRequestType) {
        this.requireAuth()

        return this.request<PlayerUserType>({
            url: API_USER_ATTRIBUTE_EMAIL_CHANGE_CANCEL(id),
            data: payload,
            method: 'PUT',
        })
    }

    static async UserConfirmEmail(id: number, payload: EmailConfirmRequestType) {
        this.requireAuth()

        return this.request<PlayerUserType>({
            url: API_USER_ATTRIBUTE_EMAIL_CONFIRM(id),
            data: payload,
            method: 'PUT',
        })
    }

    static async GetUser(id: number) {
        this.requireAuth()

        return this.request<PlayerUserType>({ url: API_GET_USER(id), method: 'GET' })
    }

    static async UserVerifyCardanoWallet(id: number, payload: UserVerifyCardanoWalletRequestType) {
        this.requireAuth()

        return this.request2<PlayerUserType>({
            url: API_USER_VERIFY_WALLET_CARDANO(id),
            data: payload,
            method: 'POST',
        })
    }

    static async UserVerifyEvmWallet(id: number, payload: UserVerifyEvmWalletRequestType) {
        this.requireAuth()

        return this.request2<PlayerUserType>({
            url: API_USER_VERIFY_WALLET_EVM(id),
            data: payload,
            method: 'POST',
        })
    }

    static async GetCardanoHardwareChallengeTransaction(
        id: number,
        address: string,
        currentTime?: string,
    ) {
        this.requireAuth()

        return this.request<{ tx: string }>({
            url: API_USER_GET_HARDWARE_WALLET_CARDANO_CHALLENGE(id),
            data: {
                address,
                currentTime,
            },
            method: 'POST',
        })
    }

    static async UserVerifyCardanoHardwareWallet(
        id: number,
        payload: UserVerifyCardanoHardwareWalletRequestType,
    ) {
        this.requireAuth()

        return this.request2<PlayerUserType>({
            url: API_USER_VERIFY_HARDWARE_WALLET_CARDANO(id),
            data: payload,
            method: 'POST',
        })
    }

    // NOT IN USE
    static async UserUnverifyWallet(id: number, walletId: number) {
        this.requireAuth()

        return this.request<UserUnverifyWalletResponseType>({
            url: API_USER_UNVERIFY_WALLET(id, walletId),
            method: 'DELETE',
        })
    }

    static async UserBulkUnverifyWallets(id: number, walletIds: number[]) {
        this.requireAuth()

        return this.request<UserBulkUnverifyWalletResponseType>({
            url: API_USER_BULK_UNVERIFY_WALLET(id),
            method: 'DELETE',
            data: { ids: walletIds },
        })
    }

    static async UserDiscordAuthorize(id: number, redirectUrl: string) {
        this.requireAuth()

        return this.request<DiscordAuthorizeResponse>({
            url: API_USER_DISCORD_AUTHORIZE,
            data: {
                playerUserId: id,
                redirectUrl,
            },
            method: 'POST',
        })
    }

    static async UserDiscordConnect(id: number, code: string, redirectUrl: string) {
        this.requireAuth()

        return this.request<PlayerUserType>({
            url: API_USER_DISCORD_CONNECT,
            data: {
                playerUserId: id,
                code,
                redirectUrl,
            },
            method: 'POST',
        })
    }

    static async UserDiscordDisconnect(id: number) {
        this.requireAuth()

        return this.request<PlayerUserType>({
            url: API_USER_DISCORD_DISCONNECT,
            data: {
                playerUserId: id,
            },
            method: 'POST',
        })
    }

    static async UserUpdateAvatar(id: number, payload: UserUpdateAvatarRequestType) {
        this.requireAuth()

        return this.request<PlayerUserType>({
            url: API_USER_ATTRIBUTE_AVATAR(id),
            data: payload,
            method: 'PUT',
        })
    }

    static async GetUserAssets(addresses: string[]) {
        return this.request<WalletAssetType[]>({
            url: API_GET_USER_ASSETS,
            data: { addresses },
            method: 'POST',
        })
    }

    /**
     * Returns a list of Staking address that have recieved new assets
     * @param addresses
     * @returns
     */
    static async GetUserAddressToSync(stakeAddresses: string[], verifiedAddresses: string[]) {
        return this.request<string[]>({
            url: API_GET_USER_ADDRESSES_TO_SYNC,
            data: { stakeAddresses, verifiedAddresses },
            method: 'POST',
        })
    }

    static async GetUserWalletAssets(assets: NftMetadataSearchRequestType) {
        return this.request<NftMetadataSearchResponse>({
            url: API_GET_USER_WALLET_ASSETS,
            data: { assets },
            method: 'POST',
        })
    }

    static async GetNftMetadata(assets: NftMetadataSearchRequestType) {
        return this.request<NftMetadataSearchResponse>({
            url: API_GET_NFT_METADATA_SEARCH,
            data: assets,
            method: 'POST',
        })
    }

    static async GetUnassignedCustomTags(playerSub: string) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagUnassignedResponseType>({
            url: API_GET_USER_UNASSIGNED_CUSTOM_TAGS(playerSub),
            method: 'GET',
        })
    }

    static async GetAssignedCustomTags(playerSub: string) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagAssignmentsResponseType>({
            url: API_GET_USER_ASSIGNED_CUSTOM_TAGS(playerSub),
            method: 'GET',
        })
    }

    static async GetProfileAssignedCustomTag(playerSub: string) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagAssignmentResponseType>({
            url: API_GET_USER_ASSIGNED_PROFILE_CUSTOM_TAG(playerSub),
            method: 'GET',
        })
    }

    static async AssignCustomTagToProfile(playerSub: string, customTagId: number) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagAssignmentResponseType>({
            url: API_ASSIGN_USER_PROFILE_CUSTOM_TAG(playerSub),
            data: {
                customTagId,
            },
            method: 'POST',
        })
    }

    static async RemoveCustomTagAssignment(playerSub: string, customTagAssignmentId: number) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagRemoveResponseType>({
            url: API_REMOVE_USER_CUSTOM_TAG_ASSIGNMENT(playerSub, customTagAssignmentId),
            method: 'DELETE',
        })
    }

    static async GetUserCopiBalance() {
        this.requireAuth()

        return this.request2<PlayerUserCopiBalanceResponse>({
            url: API_GET_USER_COPI_BALANCE,
            method: 'GET',
        })
    }

    static async DeleteUserAccount(id: number, payload: UserDeleteRequestType) {
        this.requireAuth()

        return this.request2<PlayerUserType>({
            url: API_DELETE_USER(id),
            method: 'DELETE',
            data: payload,
        })
    }

    static async UndoDeleteUserAccount(id: number) {
        this.requireAuth()

        return this.request2<PlayerUserType>({
            url: API_UNDELETE_USER(id),
            method: 'POST',
        })
    }
}

export default PlayerHttpClient
