import { PageBackgroundProps } from '@/components/ui/PageBackground'
import React from 'react'
import { SidebarProps } from '../../Sidebar/types'

export type LayoutProps = {
    children: React.ReactNode
    preContainer?: React.ReactElement
    postContainer?: React.ReactElement
    sidebar?: React.ReactNode
    sidebarProps?: SidebarProps
    title?: string | React.ReactNode
    subtitle?: string
    separator?: string
    background?: PageBackgroundProps
}
