import React, { forwardRef } from 'react'
import cx from 'classnames'
import NextImage from 'next/image'

import Conditional from '../Conditional'

import { ImageProps } from './types'
import s from './styles.module.css'
import { generateAltFromSrc } from '../../../helpers'

type Ref = HTMLImageElement

/**
 * NextJs image wrapper to apply generic styles
 */
const Image = forwardRef<Ref, ImageProps>((props, ref) => {
  const {
    children,
    notched = false,
    rounded = true,
    className,
    alt,
    src,
    caption,
    ...imageProps
  } = props

  const classes = cx(
    s.image,
    notched && s.notched,
    rounded && s.rounded,
    className
  )

  // Create alt tag from src if it has not been passed to component
  const imageAlt = alt ?? (typeof src === "string" ? generateAltFromSrc(src) : "")

  return (
    <Conditional
      wrap
      condition={!!caption}
      // eslint-disable-next-line react/no-unstable-nested-components
      component={({ children: contents }) => (
        <figure className={s.figure}>
          {contents}
          <figcaption className={s.caption}>{caption}</figcaption>
        </figure>
      )}>
      <NextImage
        ref={ref}
        src={src}
        className={classes}
        alt={imageAlt}
        {...imageProps}>
        {children}
      </NextImage>
    </Conditional>
  )
})

export default Image