
import React from 'react'
import { motion } from 'framer-motion'

import Link from '@/components/ui/Link'
import Box from '@/components/ui/Box'

import s from './styles.module.css'
import { CardProps } from './types'

const MotionLink = motion(Link)

/**
 * Card component
 * @param props 
 * @returns 
 */
function Card(props: CardProps) {
  const { href, tabIndex, children, onMouseEnter, onMouseLeave, onFocus, onBlur } = props

  const variants = {
    hover: {
      scale: 1.025,
      transition: {
        duration: 0.2
      }
    }
  }

  const card = (
    <Box
      notched
      display="flex"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={tabIndex}
      className={s.card}>
      {children}
    </Box>
  )

  if (href) return (
    <MotionLink
      href={href}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={tabIndex}
      className={s.link}
      variants={variants}
      whileFocus="hover"
      whileHover="hover">
      {card}
    </MotionLink>
  )

  return card
}

export default Card