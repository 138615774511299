import { UserAvatarCropDataType } from 'types'

const FILESTACK_BASE_URL = 'https://cdn.filestackcontent.com/'

/**
 * Is url from filestack
 * @param url
 * @returns
 */
const isFromFilestack = (url: string): boolean => url.includes(FILESTACK_BASE_URL)

/**
 * Return filestack handle
 * @param url
 * @returns
 */
const getFilestackHandle = (url: string): string => url.replace(FILESTACK_BASE_URL, '')

/**
 * Create a cropped url for filestack from a url and crop data
 * @param str
 * @returns
 */
const createCroppedImage = (url: string, cropData: UserAvatarCropDataType): string => {
    const handle = getFilestackHandle(url)
    const params = `crop=dim:[${cropData.x},${cropData.y},${cropData.width},${cropData.height}]`

    return `${FILESTACK_BASE_URL}${params}/${handle}`
}

/**
 * Resize filestack image
 * @param url
 * @param width
 * @param height
 * @returns
 */
const resizeImage = (url: string, width?: number, height?: number): string => {
    if (!isFromFilestack(url)) return url

    const handle = getFilestackHandle(url)

    if (!width && !height) return url

    const params = []

    if (width) params.push(`width:${width}`)
    if (height) params.push(`height:${height}`)

    const paramString = `resize=${params.join(',')}`

    return `${FILESTACK_BASE_URL}${paramString}/${handle}`
}
export { resizeImage, createCroppedImage }
