import React, { forwardRef } from 'react'
import cx from 'classnames'

import s from './styles.module.css'
import { SelectProps } from './types'

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const {
    className,
    placeholder,
    children,
    defaultValue = "",
    selectSize,
    hasError,
    options = null,
    ...selectProps
  } = props

  const selectClasses = cx(
    s.select,
    hasError && s.error,
    selectSize && s[selectSize],
    className
  )

  return (
    <div className={s.select_wrapper}>
      <select
        ref={ref}
        className={selectClasses}
        defaultValue={defaultValue}
        {...selectProps}>
        {
          placeholder &&
          <option value="" disabled hidden>{placeholder}</option>
        }
        {
          options &&
          options.map(({ value, name }) => <option key={value} value={value}>{name}</option>)
        }
        {children}
      </select>
    </div>
  )
})

export default Select