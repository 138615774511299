import useAuthApi from 'hooks/useAuthApi'
import { MasterLedgerHttpClient } from '../network'
import { MasterLedgerGetRewardClaimsRequest, MasterLedgerGetRewardsRequest } from '../types'

export const MASTER_LEDGER_QUERY_KEY = 'master_ledger'

export const useRewardsQuery = (params: MasterLedgerGetRewardsRequest) =>
    useAuthApi({
        queryKey: [MASTER_LEDGER_QUERY_KEY, 'rewards', params],
        queryFn: (token) => MasterLedgerHttpClient.setAccessToken(token).GetUserRewards(params),
        retry: false,
        refetchOnWindowFocus: false,
    })

export const useRewardClaimsQuery = (params: MasterLedgerGetRewardClaimsRequest) =>
    useAuthApi({
        queryKey: [MASTER_LEDGER_QUERY_KEY, 'claims', params],
        queryFn: (token) =>
            MasterLedgerHttpClient.setAccessToken(token).GetUserRewardClaims(params),
        retry: false,
        refetchOnWindowFocus: false,
    })
