import React from 'react'

import Button from '../Button'
import Modal from '../Modal'
import { ModalSetOpenType } from '../Modal/types'
import Typography from '../Typography'

import s from './styles.module.css'
import { ConfirmModalProps } from './types'

const DEFAULT_MESSAGE = "Are you sure you want to do this?"

function ConfirmModal(props: ConfirmModalProps) {
  const {
    message = DEFAULT_MESSAGE,
    triggerButtonProps,
    triggerButtonLabel,
    open = false,
    isLoading = false,
    onConfirm,
    onClose,
    manualClose = false
  } = props

  const handleConfirm = () => {
    if (onConfirm) onConfirm()
  }

  const handleClose = () => {
    if (onClose) onClose()
  }

  const trigger = (setIsOpen: ModalSetOpenType) => (
    <Button
      onClick={() => setIsOpen(true)}
      {...triggerButtonProps}
    >
      {triggerButtonLabel}
    </Button>
  )

  return (
    <Modal
      size="sm"
      open={open}
      showCloseButton={false}
      canCloseOnClickOutside={false}
      // Assume if button has no label it's not being used
      trigger={triggerButtonLabel ? trigger : undefined}>
      {
        (setIsOpen) => (
          <>
            <Typography className={s.message}>
              {message}
            </Typography>
            <div className={s.actions}>
              <Button
                onClick={() => {
                  handleConfirm()
                  if (!manualClose)
                    setIsOpen(false)
                }}
                filled
                isLoading={isLoading}
                variant="primary">
                Yes
              </Button>
              <Button
                onClick={() => {
                  handleClose()
                  if (!manualClose)
                    setIsOpen(false)
                }}
                filled
                variant="secondary">
                No
              </Button>
            </div>
          </>
        )
      }
    </Modal>
  )
}

export default ConfirmModal
