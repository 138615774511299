import { MfaSignInPayloadType, SuccessSignInResponseType } from 'types'
import t from 'helpers/translation/getTranslation'

import { AuthHttpClient, PlayerHttpClient } from '../httpClients'

type MfaProps = MfaSignInPayloadType & { id: number }

const mfa = async (data?: Record<never, string>) => {
    const { id, userCode, session } = data as MfaProps

    const { error, data: auth } = await AuthHttpClient.MfaSignin(id, {
        userCode,
        session,
    })

    if (error && error.length > 0) {
        throw new Error(t('pages.login.mfaError') as string)
    }

    if (auth) {
        const { id: authId, accessToken } = auth as SuccessSignInResponseType

        const { response, data: userData } = await PlayerHttpClient.setAccessToken(
            accessToken,
        ).GetUser(authId)

        if (response.ok) {
            return {
                ...auth,
                sub: userData?.playerUser.sub,
            } as SuccessSignInResponseType
        }
    }

    return auth || null
}

export default mfa
