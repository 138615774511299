import React, { createContext, useContext, useMemo, useState } from 'react'
import { SiteContextType, SiteProviderProps } from './types'

const SiteContext = createContext<SiteContextType>({
  announcements: [],
  sidebarMobileVisible: false,
  setSidebarMobileVisible: (visible: boolean) => visible
})

const SiteProvider = (props: SiteProviderProps) => {
  const { children, announcements = [] } = props
  const [sidebarMobileVisible, setSidebarMobileVisible] = useState<boolean>(false)

  const value = useMemo(() => ({
    announcements,
    sidebarMobileVisible,
    setSidebarMobileVisible
  }), [
    announcements,
    sidebarMobileVisible,
    setSidebarMobileVisible
  ])

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}

const useSite = () => {
  const context = useContext(SiteContext)
  if (!context) {
    throw new Error('useSiteContext must be used within an < SiteProvider />')
  }

  return context
}

export { useSite, SiteContext }
export default SiteProvider