import React, { useMemo } from 'react'
import cn from 'classnames'

import Image from '../Image'

import s from './styles.module.css'
import { getRandomInt } from '../../../helpers'
import { PageBackgroundProps } from './types'

const PageBackground = (props: PageBackgroundProps) => {
  const { images = [], greyScale = true, overlay = true } = props

  const int = useMemo(() => getRandomInt(0, images.length - 1), [images.length])

  return (
    <div className={cn(s.background_images, overlay && s.background_overlay, greyScale && s.background_greyscale)}>
      <Image
        sizes="100vw"
        fill
        alt=""
        src={images[int]}
      />
    </div>
  )
}

export default PageBackground
