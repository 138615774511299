import React from 'react'
import StickyBox from "react-sticky-box";
import cn from 'classnames'

import { Button, Typography } from '@/components/ui';
import { Close, Hamburger } from '@/components/ui/Icon';
import { useSite } from '@/providers/SiteProvider';

import { SidebarProps } from './types';
import s from './styles.module.css'

const Sidebar = (props: SidebarProps) => {
  const { children, className, offsetTop = 150, contain = false, showMobileMenuButton = true } = props
  const { setSidebarMobileVisible, sidebarMobileVisible } = useSite()

  const classes = cn(s.sticky_container, className)

  return (
    <>
      <StickyBox offsetTop={offsetTop} className={classes}>
        <aside className={s.navigation}>
          {
            contain ?
              <div className={s.sidebar_contain}>
                {children}
              </div>
              : children
          }
        </aside>
      </StickyBox>
      {showMobileMenuButton &&
        <Button
          outline
          variant="default"
          className={s.sidebar_mobile_button}
          onClick={() => setSidebarMobileVisible(true)}>
          <Hamburger />
          <Typography>Menu</Typography>
        </Button>
      }
      {
        sidebarMobileVisible &&
        <div className={s.sidebar_mobile}>
          <div className={s.sidebar_mobile_header}>
            <Button
              className={s.sidebar_mobile_close}
              onClick={() => setSidebarMobileVisible(false)}
            >
              <Close />
            </Button>
          </div>
          <div className={s.sidebar_mobile_content}>
            {children}
          </div>
        </div>

      }
    </>
  )
}

export default Sidebar