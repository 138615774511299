import { useState, useEffect } from 'react'
import { CARDANO_NETWORK_TYPE } from 'helpers/cardanoNetworkTypes'
import { getAllPolicyIdsRequiredToAddWallet } from '@/utils/Assets'

import { WalletAssetType } from '@/types/Api'
import * as Cardano from './useCardano'
import * as Evm from './useEvm'
import {
    ISignMessageOnSignErrorFn,
    ISignMessageOnSignMessageFn,
    ISignMessageOnSignTxFn,
    IWalletConnect,
    SupportedWalletType,
    UnavailableWalletVisibility,
} from './types'
import {
    Observable,
    estimateAvailableWallets,
    getDefaultSupportedWallets,
    InjectWalletListener,
    getInstalledWalletExtensions,
    WALLET_CHAIN_TYPE_CARDANO,
    WALLET_CHAIN_TYPE_EVM,
} from './utils'

const installedWalletExtensionsObserver = new Observable<Array<string>>([])

type UseWalletProps = {
    autoConnect?: boolean
    debugId?: string
}

const useWallet = (props: UseWalletProps = {}) => {
    const { autoConnect = false, debugId = '' } = props ?? {}

    const cardanoWallet = Cardano.useCardano({
        autoConnect,
        limitNetwork: CARDANO_NETWORK_TYPE,
        debugId,
    })

    const evmWallet = Evm.useEvm()

    const [connectError, setConnectError] = useState<Error>()

    const [installedExtensions, setInstalledExtensions] = useState<Array<string>>(
        installedWalletExtensionsObserver.get(),
    )

    useEffect(() => {
        installedWalletExtensionsObserver.subscribe(setInstalledExtensions)

        return () => {
            installedWalletExtensionsObserver.unsubscribe(setInstalledExtensions)
        }
    }, [])

    useEffect(() => {
        const injectWalletListener = new InjectWalletListener(() => {
            installedWalletExtensionsObserver.set(getInstalledWalletExtensions())
        })
        injectWalletListener.start()

        return () => {
            injectWalletListener.stop()
        }
    }, [])

    const connect: IWalletConnect = (wallet, onConnectSuccess, onConnectFailed) => {
        const { name, connector, chain } = wallet

        const onSuccess = () => {
            setConnectError(undefined)

            if (typeof onConnectSuccess === 'function') {
                console.log(debugId, cardanoWallet)
                onConnectSuccess(wallet)
            }
        }

        const onError = (err: Error) => {
            setConnectError(err)

            // If error is wallet not found update selected to show install guide
            if (typeof onConnectFailed === 'function') {
                onConnectFailed(err)
            }
        }

        switch (chain) {
            case WALLET_CHAIN_TYPE_CARDANO:
                // We need to disconnect otherwise selected wallet may not register correctly
                cardanoWallet.disconnect()
                cardanoWallet.connect(name, onSuccess, onError)
                break
            case WALLET_CHAIN_TYPE_EVM:
                evmWallet.connect(connector ?? name, onSuccess, onError)
                break
            default:
                break
        }
    }

    const disconnect = (wallet: SupportedWalletType) => {
        const { name, connector, chain } = wallet

        switch (chain) {
            case WALLET_CHAIN_TYPE_CARDANO:
                cardanoWallet.disconnect()
                break
            case WALLET_CHAIN_TYPE_EVM:
                evmWallet.disconnect(connector ?? name)
                break
            default:
                break
        }
    }

    const signMessage = (
        chain: string,
        message: string,
        address?: string,
        onSignMessage?: ISignMessageOnSignMessageFn,
        onSignError?: ISignMessageOnSignErrorFn,
    ) => {
        switch (chain) {
            case WALLET_CHAIN_TYPE_CARDANO:
                return cardanoWallet.signMessage(message, address, onSignMessage, onSignError)
            case WALLET_CHAIN_TYPE_EVM:
                return evmWallet.signMessage(message, address, onSignMessage, onSignError)
            default:
                break
        }
    }

    const signTx = (
        chain: string,
        tx: string,
        address?: string,
        onSignMessage?: ISignMessageOnSignTxFn,
        onSignError?: ISignMessageOnSignErrorFn,
    ) => {
        switch (chain) {
            case WALLET_CHAIN_TYPE_CARDANO:
                return cardanoWallet.signTx(tx, true, onSignMessage, onSignError)
            // case WALLET_CHAIN_TYPE_EVM:
            //return evmWallet.signTx(message, address, onSignMessage, onSignError)
            default:
                break
        }
    }

    const isWalletInstalled = (wallet: SupportedWalletType) => {
        const { name, connector, chain } = wallet

        switch (chain) {
            case WALLET_CHAIN_TYPE_CARDANO:
                return cardanoWallet.isWalletInstalled(name)
            case WALLET_CHAIN_TYPE_EVM:
                return evmWallet.isWalletInstalled(connector ?? name)
            default:
                break
        }
    }

    const getAvailableWallets = (supportedWallets: SupportedWalletType[]) => {
        const availableWallets = estimateAvailableWallets(
            supportedWallets.length > 0 ? supportedWallets : getDefaultSupportedWallets(),
            UnavailableWalletVisibility.SHOW_UNAVAILABLE,
            [],
            installedExtensions,
        )

        availableWallets.sort((a, b) => {
            const aInstalled = isWalletInstalled(a)
            const bInstalled = isWalletInstalled(b)

            if (aInstalled === bInstalled) {
                return 0
            }
            if (aInstalled) {
                return -1
            }

            return 1
        })

        return availableWallets
    }

    const getAddressesWithAssets = async (wallet: SupportedWalletType): Promise<string[]> => {
        if (!wallet) return []

        const { name, connector, chain } = wallet

        switch (chain) {
            case WALLET_CHAIN_TYPE_CARDANO:
                return await cardanoWallet.getAddressesWithAssets(
                    getAllPolicyIdsRequiredToAddWallet(),
                )
            case WALLET_CHAIN_TYPE_EVM:
                return await evmWallet.getAddressesWithAssets(connector ?? name)
            default:
                return []
        }
    }

    /**
     * Find addresses that contain accepted policy ids
     */
    const getAddressAssets = async (
        wallet: SupportedWalletType,
        limit?: 10,
    ): Promise<WalletAssetType[]> => {
        if (!wallet) return []

        switch (wallet.chain) {
            case WALLET_CHAIN_TYPE_CARDANO:
                return await cardanoWallet.getAddressAssets(
                    getAllPolicyIdsRequiredToAddWallet(),
                    limit,
                )
            case WALLET_CHAIN_TYPE_EVM:
                return await evmWallet.getAddressAssets()
            default:
                return []
        }
    }

    const getAddress = async (
        wallet: SupportedWalletType,
    ): Promise<string | string[] | undefined> => {
        const { name, connector, chain } = wallet

        switch (chain) {
            case WALLET_CHAIN_TYPE_CARDANO:
                return await cardanoWallet.getStakeAddress()
            case WALLET_CHAIN_TYPE_EVM:
                return await evmWallet.getAddress(connector ?? name)
            default:
                break
        }
    }

    return {
        connect,
        disconnect,
        signMessage,
        signTx,
        isWalletInstalled,
        getAvailableWallets,
        getAddressesWithAssets,
        getAddressAssets,
        connectError,
        getAddress,
        cardanoWallet,
        evmWallet,
    }
}

export default useWallet
