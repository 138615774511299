import { ArrayParam, BooleanParam, NumberParam, StringParam, withDefault } from 'use-query-params'
import { UseFiltersFilterConfiguration } from 'hooks/useFilters'
import { LeaderboardFilterVehiclesType } from '../types'

export const LEADERBOARD_RACING_LIMIT = 100
export const LEADERBOARD_HOLOCACHE_LIMIT = 100

export const FILTER_TYPE_RACING = [
    {
        label: 'Fastest Lap',
        value: 'LapTime',
    },
    {
        label: 'Fastest Race Time',
        value: 'RaceTime',
    },
]

export const FILTER_VALUES_RACING = {
    bestTime: [
        {
            value: 'true',
            displayName: 'Best Player Time',
        },
        {
            value: 'false',
            displayName: 'All Player Times',
        },
    ],
    track: ['Calido Yellow', 'Calido Red'],
    timeframe: [
        {
            value: 'AllTime',
            displayName: 'All Time',
        },
        {
            value: 'Hourly',
            displayName: 'Hourly',
        },
        {
            value: 'Daily',
            displayName: 'Daily',
        },
        {
            value: 'Weekly',
            displayName: 'Weekly',
        },
        {
            value: 'Monthly',
            displayName: 'Monthly',
        },
        {
            value: 'Yearly',
            displayName: 'Yearly',
        },
    ],
    vehicleClass: [
        {
            displayName: 'S Class',
            value: 'S',
        },
        {
            displayName: 'A Class',
            value: 'A',
        },
        {
            displayName: 'B Class',
            value: 'B',
        },
        {
            displayName: 'C Class',
            value: 'C',
        },
        {
            displayName: 'D Class',
            value: 'D',
        },
    ],
    vehicle: [
        {
            displayName: 'Astro IV',
            value: 'Core Dynamics|Astro IV',
        },
        {
            displayName: 'Bubblejett Bonanza  2023',
            value: 'Bubblejett|Bonanza',
        },
        {
            displayName: 'Bubblejett Bonanza OG Custom 2023',
            value: 'Bubblejett|Bonanza OG Custom',
        },
        {
            displayName: 'Bubblejett Sprinter 2022',
            value: 'Bubblejett|Sprinter',
        },
        {
            displayName: 'Bubblejett Sprinter OG Custom 2022',
            value: 'Bubblejett|Sprinter OG Custom',
        },
        {
            displayName: 'GTi Javelin 2022',
            value: 'GTi|Javelin',
        },
        {
            displayName: 'Valkyrie F9-R',
            value: 'Valkyrie|F9-R',
        },
        {
            displayName: 'Valley Raceworx T1-A',
            value: 'Valley Raceworx|T1-A',
        },
        {
            displayName: 'Valley Raceworx T1-B',
            value: 'Valley Raceworx|T1-B',
        },
        {
            displayName: 'Valley Raceworx T1-C',
            value: 'Valley Raceworx|T1-C',
        },
        {
            displayName: 'Valley Raceworx T3 2023',
            value: 'Valley Raceworx|T3',
        },
    ],
    vehicleRarity: [
        {
            displayName: 'Common',
            value: 'Common',
        },
        {
            displayName: 'Uncommon',
            value: 'Uncommon',
        },
        {
            displayName: 'Rare',
            value: 'Rare',
        },
        {
            displayName: 'Legendary',
            value: 'Legendary',
        },
        {
            displayName: 'Mythic',
            value: 'Mythic',
        },
    ],
}

export const FILTER_CONFIGURATION_RACING = [
    {
        id: 'type',
        alias: 'type',
        displayName: 'Type',
        type: withDefault(StringParam, 'RaceTime'),
        multiple: false,
        caseSensitive: true,
        required: true,
    },
    {
        id: 'bestTime',
        alias: 'bestTime',
        displayName: 'Results',
        type: withDefault(StringParam, 'true'),
        multiple: false,
        caseSensitive: true,
    },
    {
        id: 'track',
        alias: 'track',
        displayName: 'Track',
        type: withDefault(StringParam, 'Calido Yellow'),
        multiple: false,
        caseSensitive: true,
        required: true,
    },
    {
        id: 'timeframe',
        alias: 'timeframe',
        displayName: 'Timeframe',
        type: withDefault(StringParam, 'AllTime'),
        multiple: false,
        caseSensitive: true,
        required: true,
    },
    {
        id: 'vehicle',
        alias: 'vehicle',
        displayName: 'Vehicle',
        type: withDefault(ArrayParam, []),
        caseSensitive: true,
    },
    {
        id: 'vehicleClass',
        alias: 'vehicleClass',
        displayName: 'Class',
        type: StringParam,
        multiple: false,
        caseSensitive: true,
    },
    {
        id: 'vehicleRarity',
        alias: 'vehicleRarity',
        displayName: 'Rarity',
        type: withDefault(ArrayParam, []),
        caseSensitive: true,
    },
    {
        id: 'offset',
        alias: 'offset',
        displayName: 'offset',
        type: NumberParam,
        multiple: false,
        caseSensitive: true,
    },
    {
        id: 'startTime',
        alias: 'startTime',
        displayName: 'Start Time',
        type: withDefault(StringParam, ''),
        multiple: false,
        caseSensitive: true,
    },
    {
        id: 'endTime',
        alias: 'endTime',
        displayName: 'End Time',
        type: withDefault(StringParam, ''),
        multiple: false,
        caseSensitive: true,
    },
] as UseFiltersFilterConfiguration

export const FILTER_CONFIGURATION_HOLOCACHE = [
    {
        id: 'timeframe',
        alias: 'timeframe',
        displayName: 'Timeframe',
        type: withDefault(StringParam, 'AllTime'),
        multiple: false,
        caseSensitive: true,
        required: true,
    },
    {
        id: 'startTime',
        alias: 'startTime',
        displayName: 'Start Time',
        type: withDefault(StringParam, ''),
        multiple: false,
        caseSensitive: true,
    },
    {
        id: 'endTime',
        alias: 'endTime',
        displayName: 'End Time',
        type: withDefault(StringParam, ''),
        multiple: false,
        caseSensitive: true,
    },
    {
        id: 'offset',
        alias: 'offset',
        displayName: 'offset',
        type: NumberParam,
        multiple: false,
        caseSensitive: true,
    },
] as UseFiltersFilterConfiguration

export const FILTER_VALUES_HOLOCACHE = {
    timeframe: [
        {
            value: 'AllTime',
            displayName: 'All Time',
        },
        {
            value: 'Hourly',
            displayName: 'Hourly',
        },
        {
            value: 'Daily',
            displayName: 'Daily',
        },
        {
            value: 'Weekly',
            displayName: 'Weekly',
        },
        {
            value: 'Monthly',
            displayName: 'Monthly',
        },
        {
            value: 'Yearly',
            displayName: 'Yearly',
        },
    ],
}
/**
 * Get the vehicle object for the leaderboard API
 * @param vehicles
 * @returns
 */
export const getVehiclesFilter = (
    vehicles: string | string[] = [],
): LeaderboardFilterVehiclesType => {
    const filters = [] as LeaderboardFilterVehiclesType

    const vehiclesList = typeof vehicles === 'string' ? [vehicles] : vehicles

    vehiclesList.forEach((vehicle) => {
        const parts = vehicle.split('|')

        if (parts.length === 2)
            filters.push({
                manufacturer: parts[0],
                model: parts[1],
            })
    })

    return filters
}
