import React, { Children, useState, useCallback, useEffect, useRef } from 'react'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'

import s from './styles.module.css'
import { getRandomInt } from '../../../helpers'
import { SlideshowProps } from './types'

const DEFAULT_BASE_DELAY = 10000
const DEFAULT_DELAY = 3

const Slideshow = (props: SlideshowProps) => {
  const [index, setIndex] = useState(0)
  const { className, children, delay = getRandomInt(DEFAULT_BASE_DELAY, DEFAULT_DELAY * DEFAULT_BASE_DELAY), autoplay = true } = props
  const arrayChildren = Children.toArray(children);
  const autoPlayRef = useRef<() => void>()

  const classes = cx(s.slideshow, className)

  const next = useCallback(
    () => {
      if (index === arrayChildren.length - 1) {
        return setIndex(0)
      }

      setIndex(index + 1)
    },
    [index, arrayChildren],
  )

  useEffect(() => {
    autoPlayRef.current = next
  })

  useEffect(() => {
    if (!autoplay) return

    const play = () => {
      if (autoPlayRef.current)
        autoPlayRef.current()
    }

    const interval = setInterval(play, delay)

    return () => {
      if (autoplay)
        clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className={classes}>
      <AnimatePresence initial={false}>
        {arrayChildren[index]}
      </AnimatePresence>
    </div>
  )
}

export default Slideshow
