import NextAuth, { NextAuthOptions } from 'next-auth'
import { JWT } from 'next-auth/jwt'
import CredentialsProvider from 'next-auth/providers/credentials'

import { AuthHttpClient } from '@/network/httpClients'
import { credentials, mfa } from '@/network/auth'
import { MFA_PROVIDER_ID, CREDENTIALS_PROVIDER_ID, ERROR_REFRESH_ACCESS_TOKEN } from '@/consts'

import {
    AUTH_REFRESH_TOKEN_LIFESPAN_SECS,
    AUTH_ACCESS_TOKEN_LIFESPAN_SECS,
    AUTH_ACCESS_TOKEN_REFRESH_SECS_BEFORE_EXPIRE,
    createAccessTokenExpireTimeStamp,
} from 'types/Api'

export const options: NextAuthOptions = {
    secret: process.env.NEXTAUTH_SECRET,
    providers: [
        CredentialsProvider({
            id: CREDENTIALS_PROVIDER_ID,
            credentials: {},
            authorize: credentials,
        }),
        CredentialsProvider({
            id: MFA_PROVIDER_ID,
            credentials: {},
            authorize: mfa,
        }),
    ],
    session: {
        strategy: 'jwt',
        maxAge: AUTH_REFRESH_TOKEN_LIFESPAN_SECS,
    },
    jwt: {
        maxAge: AUTH_ACCESS_TOKEN_LIFESPAN_SECS,
    },
    callbacks: {
        jwt: async ({ token, user }) => {
            if (user) {
                // This will only be executed at login. Each next invocation will skip this part.
                return {
                    id: user.id,
                    sub: user.sub,
                    accessToken: user.accessToken,
                    // Access token expires after one hour
                    accessTokenExpiry: createAccessTokenExpireTimeStamp(),
                    refreshToken: user.refreshToken,
                    sessionToken: user.sessionToken,
                    forceMfa: user.forceMfa,
                    unconfirmed: user.unconfirmed,
                } as JWT
            }

            // TODO:: Explore forcing token change on pw change
            // if (trigger === 'update') {
            //     if (session && session.refreshToken && session.accessToken) {
            //         token.refreshToken = session.refreshToken
            //         token.accessToken = session.accessToken
            //         token.accessTokenExpiry = createAccessTokenExpireTimeStamp()
            //     }

            //     console.log(token)
            // }

            // Check if token needs refreshed, will refresh if it has almost expired (5 minutes before)
            const shouldRefresh =
                Math.round(
                    token.accessTokenExpiry -
                        AUTH_ACCESS_TOKEN_REFRESH_SECS_BEFORE_EXPIRE * 1000 -
                        Date.now(),
                ) < 0

            // If the token is still valid, just return it.
            if (!shouldRefresh) {
                return token
            }

            try {
                // Get a new set of tokens with a refreshToken
                const { response, data } = await AuthHttpClient.RefreshAccessToken(
                    token.refreshToken,
                )

                // Return updated tokens + existing token information
                if (response.ok && data) {
                    return {
                        ...token,
                        accessToken: data.accessToken,
                        accessTokenExpiry: createAccessTokenExpireTimeStamp(),
                    } as JWT
                }

                // The error property will be used client-side to handle the refresh token error
                return {
                    ...token,
                    error: ERROR_REFRESH_ACCESS_TOKEN,
                } as JWT
            } catch (error) {
                return {
                    ...token,
                    error: ERROR_REFRESH_ACCESS_TOKEN,
                } as JWT
            }
        },
        session: async ({ session, token }) => ({
            ...session,
            error: token.error,
            accessToken: token.accessToken,
            sessionToken: token.sessionToken,
            forceMfa: token.forceMfa,
            unconfirmed: token.unconfirmed,
            user: {
                id: token.id,
                sub: token.sub,
            },
        }),
    },
    pages: {
        signIn: '/login',
    },
}

export default NextAuth(options)
