import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Base = (props: SVGIconProps) => {
  const { width, height = 10, showTitle = true, ...svgProps } = props
  const svgWith = width || (showTitle ? 47 : 10)

  return (
    <SVGIcon width={svgWith} height={height} aria-label="Base" {...svgProps}>
      <path d="M4.88029 9.77778C7.58521 9.77778 9.77778 7.58912 9.77778 4.88889C9.77778 2.18866 7.58521 0 4.88029 0C2.3142 0 0.209244 1.97042 0 4.47783H6.47328V5.29995H0C0.209244 7.80736 2.3142 9.77778 4.88029 9.77778Z" fill="white" />
      {
        showTitle &&
        <>
          <path d="M41.3438 9.86803V1.22803H47.0917V1.92403H42.1597V5.04403H46.6117V5.74003H42.1597V9.17203H47.2118V9.86803H41.3438Z" fill="white" />
          <path d="M34.3438 7.288C34.3758 7.672 34.4598 8 34.5958 8.272C34.7398 8.536 34.9198 8.752 35.1358 8.92C35.3598 9.088 35.6158 9.212 35.9038 9.292C36.1918 9.364 36.4998 9.4 36.8278 9.4C37.2118 9.4 37.5438 9.352 37.8238 9.256C38.1038 9.16 38.3318 9.032 38.5078 8.872C38.6838 8.704 38.8118 8.512 38.8918 8.296C38.9798 8.08 39.0238 7.852 39.0238 7.612C39.0238 7.276 38.9478 7.004 38.7958 6.796C38.6438 6.588 38.4438 6.42 38.1958 6.292C37.9558 6.156 37.6758 6.048 37.3558 5.968C37.0438 5.888 36.7198 5.812 36.3838 5.74C36.0558 5.66 35.7318 5.568 35.4118 5.464C35.0998 5.36 34.8198 5.22 34.5718 5.044C34.3318 4.868 34.1358 4.644 33.9838 4.372C33.8318 4.092 33.7558 3.74 33.7558 3.316C33.7558 3.02 33.8158 2.732 33.9358 2.452C34.0638 2.172 34.2478 1.924 34.4878 1.708C34.7358 1.492 35.0438 1.32 35.4118 1.192C35.7798 1.064 36.2038 1 36.6838 1C37.1718 1 37.5958 1.068 37.9558 1.204C38.3238 1.34 38.6278 1.528 38.8678 1.768C39.1078 2 39.2878 2.272 39.4078 2.584C39.5278 2.896 39.5878 3.228 39.5878 3.58H38.8078C38.8078 3.252 38.7478 2.968 38.6278 2.728C38.5158 2.488 38.3598 2.292 38.1598 2.14C37.9598 1.988 37.7318 1.876 37.4758 1.804C37.2198 1.732 36.9558 1.696 36.6838 1.696C36.2678 1.696 35.9158 1.756 35.6278 1.876C35.3478 1.988 35.1238 2.136 34.9558 2.32C34.7878 2.504 34.6718 2.708 34.6078 2.932C34.5438 3.156 34.5318 3.38 34.5718 3.604C34.6198 3.884 34.7318 4.108 34.9078 4.276C35.0838 4.444 35.2998 4.584 35.5558 4.696C35.8118 4.8 36.0918 4.888 36.3958 4.96C36.7078 5.024 37.0238 5.096 37.3438 5.176C37.6638 5.248 37.9718 5.336 38.2678 5.44C38.5718 5.544 38.8398 5.684 39.0718 5.86C39.3038 6.036 39.4878 6.264 39.6238 6.544C39.7678 6.816 39.8398 7.16 39.8398 7.576C39.8398 8.376 39.5638 8.996 39.0118 9.436C38.4598 9.876 37.6838 10.096 36.6838 10.096C36.2358 10.096 35.8198 10.036 35.4358 9.916C35.0518 9.804 34.7198 9.632 34.4398 9.4C34.1598 9.16 33.9398 8.868 33.7798 8.524C33.6278 8.172 33.5518 7.76 33.5518 7.288H34.3438Z" fill="white" />
          <path d="M25.2119 9.86803L28.6079 1.22803H29.5319L32.8439 9.86803H32.0039L31.0079 7.15603H27.0719L26.0519 9.86803H25.2119ZM27.3479 6.46003H30.7319L29.0399 1.92403L27.3479 6.46003Z" fill="white" />
          <path d="M18.816 9.17203H20.892C21.34 9.17203 21.728 9.15203 22.056 9.11203C22.392 9.07203 22.668 8.99203 22.884 8.87203C23.1 8.74403 23.26 8.56803 23.364 8.34403C23.476 8.11203 23.532 7.80803 23.532 7.43203C23.532 7.07203 23.472 6.78403 23.352 6.56803C23.232 6.34403 23.06 6.17203 22.836 6.05203C22.612 5.93203 22.344 5.85203 22.032 5.81203C21.72 5.76403 21.372 5.74003 20.988 5.74003H18.816V9.17203ZM18.816 5.04403H20.88C21.304 5.04403 21.66 5.00803 21.948 4.93603C22.236 4.86403 22.468 4.76003 22.644 4.62403C22.82 4.48003 22.944 4.30803 23.016 4.10803C23.096 3.90003 23.136 3.66403 23.136 3.40003C23.136 3.12803 23.092 2.90003 23.004 2.71603C22.916 2.52403 22.776 2.37203 22.584 2.26003C22.392 2.14003 22.14 2.05603 21.828 2.00803C21.524 1.95203 21.148 1.92403 20.7 1.92403H18.816V5.04403ZM18 9.86803V1.22803H21.12C21.624 1.22803 22.056 1.28003 22.416 1.38403C22.776 1.48803 23.068 1.63603 23.292 1.82803C23.524 2.02003 23.692 2.25203 23.796 2.52403C23.9 2.78803 23.952 3.08003 23.952 3.40003C23.952 3.70403 23.912 3.96803 23.832 4.19203C23.752 4.40803 23.648 4.59203 23.52 4.74403C23.392 4.88803 23.244 5.00403 23.076 5.09203C22.916 5.17203 22.748 5.22803 22.572 5.26003V5.28403C23.172 5.37203 23.616 5.60803 23.904 5.99203C24.2 6.37603 24.348 6.85603 24.348 7.43203C24.348 7.88003 24.276 8.26003 24.132 8.57203C23.996 8.88403 23.804 9.13603 23.556 9.32803C23.308 9.52003 23.008 9.66003 22.656 9.74803C22.312 9.82803 21.936 9.86803 21.528 9.86803H18Z" fill="white" />
        </>
      }
    </SVGIcon>
  )
}

export default Base