import axios from 'axios'
import getBaseAPI from './getBaseAPI'
import { KoiosAddressAssetType } from './types'
import handleError from './errors'

const fetchAssets = async (
    addresses: string[],
    offset = 0,
    limit = 1000,
): Promise<KoiosAddressAssetType[]> => {
    const response = await axios.post(
        `${getBaseAPI()}/api/v1/address_assets?offset=${offset}&limit=${limit}`,
        {
            _addresses: addresses,
        },
        {
            headers: {
                Authorization: `Bearer ${process.env.NEXT_PUBLIC_KOIOS_API_KEY}`,
            },
        },
    )

    if (response.status !== 200)
        throw new Error(`Failed to retrieve assets: ${response.statusText}`)

    handleError(response)

    return response.data as KoiosAddressAssetType[]
}

export default fetchAssets
