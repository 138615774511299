import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Ethereum = (props: SVGIconProps) => {
  const { width, height = 16, showTitle = true, ...svgProps } = props
  const svgWith = width || (showTitle ? 84 : 9)

  return (
    <SVGIcon width={svgWith} height={height} aria-label="Ethereum" {...svgProps}>
      <path d="M4.50179 0L9 8.14681L4.50179 5.91535V0Z" fill="#C9C9C9" />
      <path d="M4.50179 5.91528L9 8.14675L4.50179 11.0516V5.91528Z" fill="#D5D5D5" />
      <path d="M0.00362539 8.14681L4.50183 0V5.91535L0.00362539 8.14681Z" fill="#D5D5D5" />
      <path d="M4.50183 11.0516L0.00362539 8.14675L4.50183 5.91528V11.0516Z" fill="white" />
      <path d="M9 9.07861L4.50179 16.0001V11.9795L9 9.07861Z" fill="#C9C9C9" />
      <path d="M4.50183 11.9795V16.0001L3.95775e-05 9.07861L4.50183 11.9795Z" fill="#D5D5D5" />
      {
        showTitle &&
        <>
          <path d="M75.2227 4H76.8926L79.3652 11.2773L81.8203 4H83.4727V12.6074H82.3652V7.52734C82.3652 7.35156 82.3691 7.06055 82.377 6.6543C82.3848 6.24805 82.3887 5.8125 82.3887 5.34766L79.9336 12.6074H78.7793L76.3066 5.34766V5.61133C76.3066 5.82227 76.3105 6.14453 76.3184 6.57812C76.3301 7.00781 76.3359 7.32422 76.3359 7.52734V12.6074H75.2227V4Z" fill="white" />
          <path d="M67.8457 4V9.32031C67.8457 9.94531 67.9629 10.4648 68.1973 10.8789C68.5449 11.5039 69.1309 11.8164 69.9551 11.8164C70.9434 11.8164 71.6152 11.4785 71.9707 10.8027C72.1621 10.4355 72.2578 9.94141 72.2578 9.32031V4H73.4414V8.83398C73.4414 9.89258 73.2988 10.707 73.0137 11.2773C72.4902 12.3164 71.502 12.8359 70.0488 12.8359C68.5957 12.8359 67.6094 12.3164 67.0898 11.2773C66.8047 10.707 66.6621 9.89258 66.6621 8.83398V4H67.8457Z" fill="white" />
          <path d="M58.6875 4H64.9629V5.05469H59.8242V7.66797H64.5762V8.66406H59.8242V11.582H65.0508V12.6074H58.6875V4Z" fill="white" />
          <path d="M53.9062 7.94336C54.4531 7.94336 54.8848 7.83398 55.2012 7.61523C55.5215 7.39648 55.6816 7.00195 55.6816 6.43164C55.6816 5.81836 55.459 5.40039 55.0137 5.17773C54.7754 5.06055 54.457 5.00195 54.0586 5.00195H51.2109V7.94336H53.9062ZM50.0449 4H54.0293C54.6855 4 55.2266 4.0957 55.6523 4.28711C56.4609 4.6543 56.8652 5.33203 56.8652 6.32031C56.8652 6.83594 56.7578 7.25781 56.543 7.58594C56.332 7.91406 56.0352 8.17773 55.6523 8.37695C55.9883 8.51367 56.2402 8.69336 56.4082 8.91602C56.5801 9.13867 56.6758 9.5 56.6953 10L56.7363 11.1543C56.748 11.4824 56.7754 11.7266 56.8184 11.8867C56.8887 12.1602 57.0137 12.3359 57.1934 12.4141V12.6074H55.7637C55.7246 12.5332 55.6934 12.4375 55.6699 12.3203C55.6465 12.2031 55.627 11.9766 55.6113 11.6406L55.541 10.2051C55.5137 9.64258 55.3047 9.26562 54.9141 9.07422C54.6914 8.96875 54.3418 8.91602 53.8652 8.91602H51.2109V12.6074H50.0449V4Z" fill="white" />
          <path d="M42.0117 4H48.2871V5.05469H43.1484V7.66797H47.9004V8.66406H43.1484V11.582H48.375V12.6074H42.0117V4Z" fill="white" />
          <path d="M33.2578 4H34.4355V7.55664H38.9121V4H40.0898V12.6074H38.9121V8.58203H34.4355V12.6074H33.2578V4Z" fill="white" />
          <path d="M32.1562 4V5.02539H29.2559V12.6074H28.0723V5.02539H25.1719V4H32.1562Z" fill="white" />
          <path d="M18 4H24.2754V5.05469H19.1367V7.66797H23.8887V8.66406H19.1367V11.582H24.3633V12.6074H18V4Z" fill="white" />
        </>
      }
    </SVGIcon>
  )
}

export default Ethereum
