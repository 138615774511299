import { motion } from 'framer-motion';
import React, { memo } from 'react'

import s from './styles.module.css'
import { SlideProps } from './types';

const fadeVariant = {
  enter: () => ({
    opacity: 0
  }),
  center: {
    zIndex: 1,
    opacity: 1
  },
  exit: () => ({
    zIndex: 0,
    opacity: 0
  })
};

const Slide = (props: SlideProps) => {
  const { children, variants = fadeVariant } = props

  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        opacity: { duration: 0.2 }
      }}
      className={s.slide}>
      {children}
    </motion.div>
  )
}

export default memo(Slide)
