import { UserType } from 'types'
import * as gateUtils from './utils'

const Gate = (user?: UserType) => {
    const playerRoles = !user ? [] : user.playerRoles.map((role) => role.name)
    const gameComponents = !user
        ? []
        : user.gameRoles.flatMap((component) =>
              component.gameComponents.map((gameComponent) => gameComponent.name),
          )

    /**
     * Determines if a user is assigned a given role.
     * @param {string} role - the role to check against.
     * @returns {boolean}
     */
    const allowsRole = (role: string) => gateUtils.allows(playerRoles, role)

    /**
     * Determines if a user is assigned all given roles.
     * @param {[string]} roles - the roles to check against.
     * @returns {boolean}
     */
    const allRoles = (roles: string[]) => gateUtils.all(playerRoles, roles)

    /**
     * Determines if a user is assigned at least one of the given roles.
     * @param {[string]} roles - the roles to check against.
     * @returns {boolean}
     */
    const anyRoles = (roles: string[]) => gateUtils.any(playerRoles, roles)

    /**
     * Determines which given roles are missing from the user's roles.
     * @param {[string]} roles - the roles to check against.
     * @returns {[string]}
     */
    const missingRoles = (roles: string[]) => gateUtils.any(playerRoles, roles)

    /**
     * Determines if a user is assigned a given game component.
     * @param {string} role - the game component to check against.
     * @returns {boolean}
     */
    const allowsGameComponent = (role: string) => gateUtils.allows(gameComponents, role)

    /**
     * Determines if a user is assigned all given game components.
     * @param {[string]} roles - the game components to check against.
     * @returns {boolean}
     */
    const allGameComponents = (roles: string[]) => gateUtils.all(gameComponents, roles)

    /**
     * Determines if a user is assigned at least one of the given game components.
     * @param {[string]} roles - the game components to check against.
     * @returns {boolean}
     */
    const anyGameComponents = (roles: string[]) => gateUtils.any(gameComponents, roles)

    /**
     * Determines which given game components are missing from the user's game components.
     * @param {[string]} roles - the game components to check against.
     * @returns {[string]}
     */
    const missingGameComponents = (roles: string[]) => gateUtils.any(gameComponents, roles)

    return {
        allowsRole,
        allRoles,
        anyRoles,
        missingRoles,
        allowsGameComponent,
        allGameComponents,
        anyGameComponents,
        missingGameComponents,
    }
}

export default Gate
