import useAuthApi from 'hooks/useAuthApi'
import { NodeListRequest, OnChainNodesRequest } from '@/types/Nodes'
import { NodeHttpClient } from '../network'

export const NODES_QUERY_KEY = 'nodes'

export const useNodesOverviewQuery = () =>
    useAuthApi({
        queryKey: [NODES_QUERY_KEY, 'overview'],
        queryFn: (token) => NodeHttpClient.setAccessToken(token).GetNodesOverview(),
        retry: false,
        refetchOnWindowFocus: false,
    })

export const useNodesQuery = (params: NodeListRequest, refetchInterval: number | false = false) =>
    useAuthApi({
        queryKey: [NODES_QUERY_KEY, params],
        queryFn: (token) => NodeHttpClient.setAccessToken(token).GetNodeList(params),
        retry: false,
        refetchOnWindowFocus: false,
        refetchInterval,
    })

export const useOnChainNodesQuery = (
    params: OnChainNodesRequest,
    refetchInterval: number | false = false,
) =>
    useAuthApi({
        queryKey: [NODES_QUERY_KEY, params],
        queryFn: (token) => NodeHttpClient.setAccessToken(token).GetOnChainNodes(params),
        retry: false,
        refetchOnWindowFocus: false,
        refetchInterval,
    })
