import React, { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { toast } from 'react-toastify'

import { Button } from '@/components/ui'
import { NavItem } from '@/components/ui/Navigation'
import { useAuth } from '@/providers/AuthenticationProvider'
import { REQUIRED_GAME_COMPONENTS_FOR_GAME_ACCESS, REQUIRED_GAME_COMPONENTS_FOR_LAUNCHER } from '@/consts'
import { GA_EVENT_ACTION_GAME_LAUNCHER_DOWNLOAD, useGoogleTags } from 'hooks/useGoogleTags'
import { Gate } from '@/network/auth/gate'
import t from 'helpers/translation/getTranslation'

const DownloadLauncherButton = () => {
  const [canDownload, setCanDownload] = useState<boolean>(false)
  const { isLoggedIn, user } = useAuth()
  const { event } = useGoogleTags()

  useEffect(() => {
    // Only show button for Windows users
    if (isLoggedIn && user && navigator.platform.indexOf('Win') !== -1) {

      // If user has access to the game assume they have launcher access
      const hasPermission = Gate(user).anyGameComponents([...REQUIRED_GAME_COMPONENTS_FOR_LAUNCHER, ...REQUIRED_GAME_COMPONENTS_FOR_GAME_ACCESS])

      if (hasPermission) {
        setCanDownload(true)
      }
    }
  }, [isLoggedIn, user])

  const handleDownload = async () => {
    try {
      // Request download
      const response = await fetch('/api/download/game-launcher');

      // Check if the response was successful
      if (response.ok) {
        // Create a temporary download link and trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = response.url;
        downloadLink.download = 'CornucopiasLauncher.exe';
        downloadLink.click();
      } else {
        toast.error('Failed to initiate the download. Please try again or contact support.')
      }

      event({
        action: GA_EVENT_ACTION_GAME_LAUNCHER_DOWNLOAD,
      })
    } catch (error) {
      toast.error(t("errors.genericContactAdmin"))
    }
  };

  if (!canDownload) return <></>

  return (
    <NavItem>
      <Button
        filled
        onClick={handleDownload}
        variant="primary">
        DOWNLOAD
      </Button>
    </NavItem>
  )
}

// Because this component relies on the navigator class, ssr is not needed
export default dynamic(() => Promise.resolve(DownloadLauncherButton), {
  ssr: false,
});