import { useQuery } from '@tanstack/react-query'
import MarketplaceHttpClient from '@/network/httpClients/MarketplaceHttpClient'

export const MARKETPLACE_QUERY_KEY = 'marketplace'

/**
 * Get stats on a sale
 * @param saleKey
 * @returns
 */
export const useMarketplaceSaleOverview = (saleKey: string) =>
    useQuery({
        queryKey: [MARKETPLACE_QUERY_KEY, saleKey],
        queryFn: () => MarketplaceHttpClient.GetSaleOverviewStats(saleKey),
        retry: false,
        refetchOnWindowFocus: false,
    })
