import useAuthApi from 'hooks/useAuthApi'
import { PlayerHttpClient } from '../network'

export const PLAYER_QUERY_KEY = 'player'

export const usePlayerCopiBalance = (refetchInterval: number | false = false) =>
    useAuthApi({
        queryKey: [PLAYER_QUERY_KEY, 'balance'],
        queryFn: (token) => PlayerHttpClient.setAccessToken(token).GetUserCopiBalance(),
        retry: false,
        refetchOnWindowFocus: false,
        refetchInterval,
    })

export const usePlayerQuery = (id: number) =>
    useAuthApi({
        queryKey: [PLAYER_QUERY_KEY, id],
        queryFn: (token) => PlayerHttpClient.setAccessToken(token).GetUser(id),
        retry: false,
        refetchOnWindowFocus: false,
    })
