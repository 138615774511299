import {
    API_GAME_CLEAR_USER_HOLOCACHE,
    API_GAME_DATA_HOLOCACHE_LEADERBOARD,
    API_GAME_DATA_RACE_LEADERBOARD,
} from 'consts/apiRoutes'
import {
    LeaderboardHolocacheRequest,
    LeaderboardHolocacheResponse,
    LeaderboardRaceRequest,
    LeaderboardRaceResponse,
} from '@/types/Game'

import HttpClientBase from './HttpClientBase'

class GameHttpClient extends HttpClientBase {
    static setAccessToken(accessToken?: string) {
        super.setAccessToken(accessToken)

        return this
    }

    static async GetHolocacheLeaderboard(params: LeaderboardHolocacheRequest) {
        return this.request2<LeaderboardHolocacheResponse>({
            url: API_GAME_DATA_HOLOCACHE_LEADERBOARD,
            method: 'POST',
            data: params,
        })
    }

    static async GetRaceLeaderboard(params: LeaderboardRaceRequest) {
        return this.request2<LeaderboardRaceResponse>({
            url: API_GAME_DATA_RACE_LEADERBOARD,
            method: 'POST',
            data: params,
        })
    }

    static async ClearUserHolocaches() {
        this.requireAuth()

        return this.request2<any>({
            url: API_GAME_CLEAR_USER_HOLOCACHE,
            method: 'POST',
            // Empty body is required otherwise API fails
            data: {},
        })
    }
}

export default GameHttpClient
