import NodeCache from 'node-cache'
import axios, { AxiosError } from 'axios'

import encodeId from 'helpers/encodeId'
import { API_GET_NFT_METADATA_SEARCH } from 'consts/apiRoutes'

import { getGameAssetsPolicyIds, hexToString } from './Assets'
import { fetchAllAssets, fetchAssets, getAllAccountUtxos } from './Koios'
import { NftMetadataSearchResponse, NftMetadataSearchType, WalletAssetType } from 'types'

// Create a cache
const CACHE = new NodeCache()

// Simple url check to ensure we have some sort of url
function isUrl(str: string) {
    // Regular expression pattern to match URL
    const urlPattern = /^(?:https?:\/\/|ipfs:\/\/|\/)[^\s/$.?#].[^\s]*$/i

    // Test the string against the pattern
    return urlPattern.test(str)
}

/**
 * Using metadata create an asset containing all information
 * @param policyId
 * @param metadata
 * @param quantity
 * @param id
 * @returns
 */
const createAssetWithMetadata = (
    policyId: string,
    assetNameHex: string,
    metadata: NftMetadataSearchType[],
    quantity: number | string,
    id: string,
): WalletAssetType => {
    const meta = metadata.find(
        (data) => data.policyId === policyId && data.assetName === assetNameHex,
    )

    let thumbnail = '/_assets/placeholder.webp'

    if (meta?.nftSrcImage && meta?.nftSrcImage !== '' && isUrl(meta?.nftSrcImage)) {
        if (meta.nftSrcImage.startsWith('ipfs://')) {
            // Parse ipfs image, otherwise show fallback
            thumbnail = `https://ipfs.io/ipfs/${meta?.nftSrcImage.replace('ipfs://', '')}`
        } else {
            thumbnail = meta?.nftSrcImage
        }
    }

    return {
        id,
        quantity: parseInt(quantity.toString(), 10),
        assetName: meta?.assetName ?? hexToString(assetNameHex),
        policyId: meta?.policyId ?? policyId,
        thumbnail,
        ...meta,
    } as WalletAssetType
}

const getPlayerAssets = async (
    addresses: string[],
    offset = 0,
    limit: number | null = null,
): Promise<WalletAssetType[]> => {
    // Create unique string with addresses
    const cacheId = `PLAYER_ACCOUNT_ASSETS__${encodeId(addresses)}`

    // If API has cache retrieve it
    if (CACHE.has(cacheId)) {
        const cache = CACHE.get(cacheId)

        if (cache) return cache as WalletAssetType[]
    }

    try {
        let assets = []

        if (!limit) assets = await fetchAllAssets(addresses)
        else assets = await fetchAssets(addresses, offset, limit)

        if (assets) {
            let seededData = [] as WalletAssetType[]

            if (assets.length > 0) {
                // Filter results to only return valid assets
                const cornucopiasAssets = assets.filter((asset) =>
                    getGameAssetsPolicyIds().includes(asset.policy_id),
                )

                if (cornucopiasAssets.length === 0) return []

                // Create payload
                const nftMetadataSearchPayload = cornucopiasAssets.map(
                    ({ policy_id, asset_name }) => ({
                        policyId: policy_id,
                        assetName: asset_name,
                    }),
                ) as NftMetadataSearchType[]

                // Retrieve metadata
                const metadata = await axios
                    .post(`${API_GET_NFT_METADATA_SEARCH}`, nftMetadataSearchPayload)
                    .then(({ data }: { data: NftMetadataSearchResponse }) => data)

                // Merge metadata with user assets
                if (metadata) {
                    seededData = cornucopiasAssets.map((asset, index) =>
                        createAssetWithMetadata(
                            asset.policy_id,
                            asset.asset_name,
                            metadata || [],
                            asset.quantity,
                            encodeId([asset.fingerprint, index.toString(), asset.address]),
                        ),
                    ) as WalletAssetType[]
                }
            }

            // Cache response, 5 minutes
            CACHE.set(cacheId, seededData, 300)

            return seededData
        }
    } catch (error: unknown | AxiosError) {
        if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            throw new Error(
                `${error.message} ${
                    error.response?.statusText ? `- ${error.response?.statusText}` : ''
                }`,
            )
        } else {
            throw new Error(`Error: ${(error as Error).message}`)
        }
    }

    return []
}

/**
 * Check if new payments addresses exist with assets
 * Primarily for multi address wallets
 * @param stakeAddresses
 * @param savedAddresses
 * @returns
 */
const getStakeAddressesWithNewAssets = async (
    stakeAddresses: string[],
    savedAddresses: string[],
): Promise<string[]> => {
    const utxos = await getAllAccountUtxos(stakeAddresses)

    const unsavedAddress = utxos.filter((utxo) => {
        const hasUnsavedAddress = !savedAddresses.includes(utxo.address)
        const hasAssets = utxo.asset_list.some((asset) =>
            getGameAssetsPolicyIds().includes(asset.policy_id),
        )

        return hasUnsavedAddress && hasAssets
    })

    return unsavedAddress.map((utxo) => utxo.stake_address)
}

export { getPlayerAssets, createAssetWithMetadata, getStakeAddressesWithNewAssets }
