import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { signOut, useSession } from 'next-auth/react'
import { ERROR_REFRESH_ACCESS_TOKEN } from 'consts/auth'
import { toast } from 'react-toastify'
import { Session } from 'next-auth'

import { HttpClientResponseError } from '../network'

const useAuthApi = <TQueryKey extends QueryKey, TQueryFnData, TError, TData = TQueryFnData>(
    props: {
        queryKey: TQueryKey
        queryFn: (token?: string) => Promise<TQueryFnData>
        showErrorToast?: boolean
    } & Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
) => {
    const { data: session, update } = useSession()
    const { queryKey, queryFn: fetcher, showErrorToast = true, ...options } = props
    let newSession: Session | null

    return useQuery({
        queryKey,
        queryFn: async () => {
            try {
                const response = await fetcher(session?.accessToken)

                return response
            } catch (error) {
                try {
                    // Handle 401 otherwise trigger a toast if enabled
                    if (error instanceof HttpClientResponseError && error.code === 401 && session) {
                        newSession = await update()

                        if (newSession) {
                            if (!newSession.error) {
                                return await fetcher(newSession?.accessToken)
                            }
                        }
                    }

                    throw error
                } catch (innerError) {
                    // If we still have not got a valid access token sign the user out
                    if (innerError instanceof HttpClientResponseError && innerError.code === 401) {
                        if (newSession?.error === ERROR_REFRESH_ACCESS_TOKEN || !newSession) {
                            toast.error(`Unauthorized`)
                            signOut()
                            throw innerError
                        }
                    }

                    if (showErrorToast) {
                        toast.error(`Error: ${(innerError as Error).message}`)
                    }

                    throw innerError
                }
            }
        },
        ...options,
    })
}

export default useAuthApi
