import React, { useState, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Dialog } from '@headlessui/react'
import cx from 'classnames'

import { ModalProps } from './types'
import Mask from '../Mask'

import s from './styles.module.css'
import { Close } from '../Icon'
import Button from '../Button'

function Modal(props: ModalProps) {
  const {
    title,
    description,
    trigger,
    size,
    children,
    open = false,
    onClose,
    onClickOutside,
    modalClassName,
    modalTitleClassName,
    showCloseButton = true,
    canCloseOnClickOutside = true
  } = props
  const [isOpen, setIsOpen] = useState<boolean>(open)

  useEffect(() => {
    if (open !== isOpen)
      setIsOpen(open)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])


  const modalWrapperClasses = cx(s.modal_wrapper, size && s[size])
  const modalClasses = cx(s.modal, modalClassName)
  const modalTitleClasses = cx(s.modal_title, modalTitleClassName)

  const handleClickOutside = () => {
    if (onClickOutside) onClickOutside()
    if (canCloseOnClickOutside) setIsOpen(false)
  }

  const handleOnClose = () => {
    if (onClose) onClose()

    setIsOpen(false)
  }

  return (
    <>
      {
        trigger && trigger(setIsOpen, isOpen)
      }
      <AnimatePresence>
        {
          isOpen &&
          <Dialog
            open={isOpen}
            className="relative z-30"
            onClose={handleClickOutside}>
            <div className={s.overlay} aria-hidden="true" />

            <div className={modalWrapperClasses}>
              <motion.div
                className={s.modal_inner_wrapper}
                initial={{
                  opacity: 0,
                  scale: 0.75,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    ease: "easeOut",
                    duration: 0.15,
                  },
                }}
                exit={{
                  opacity: 0,
                  scale: 0.75,
                  transition: {
                    ease: "easeIn",
                    duration: 0.15,
                  },
                }}>
                <Mask>
                  <Dialog.Panel className={modalClasses}>
                    {
                      (title || showCloseButton) &&
                      <Dialog.Title
                        as="h3"
                        className={modalTitleClasses}>
                        <span>{title}</span>
                        {
                          showCloseButton &&
                          <Button
                            className={s.close_button}
                            onClick={handleOnClose}>
                            <Close />
                          </Button>
                        }
                      </Dialog.Title>
                    }
                    <Dialog.Description>
                      {description}
                    </Dialog.Description>
                    {
                      children && (
                        typeof children === "function" ? children(setIsOpen, isOpen) : children
                      )
                    }
                  </Dialog.Panel>
                </Mask>
              </motion.div>
            </div>
          </Dialog>

        }
      </AnimatePresence>
    </>
  )
}

export default Modal
