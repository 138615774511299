import React from 'react'
import cx from 'classnames'

import { PRIMARY_MENU_OPTIONS, SECONDARY_MENU_OPTIONS } from 'consts/navigation'
import { MegaMenu, useMegaMenu, MegaMenuPanel, NavBar, NavItem } from '@/components/ui/Navigation'

// ESLint didn't like the name "Link", so required to import under different name
import NavLink, { Link as ButtonLink } from '@/components/ui/Navigation/NavLink'
import { NavigationItems, NavigationPrimaryMenuItems } from 'types/navigation'
import Button from '@/components/ui/Button'

import { MobileMenu, AccountMenu } from './Navigation'
import DownloadLauncherButton from './Navigation/DownloadLauncherButton'
import Brand from '../../ui/Brand'

import { HeaderProps } from './types'
import s from './styles.module.css'

/**
 * Site header
 * @returns 
 */
const Header = (props: HeaderProps) => {
  const { className, megaMenuClassName, accountMenuItemsClassName } = props
  const primaryMenuOptions: NavigationPrimaryMenuItems = PRIMARY_MENU_OPTIONS
  const secondaryMenuOptions: NavigationItems = SECONDARY_MENU_OPTIONS

  const { menuRef, isActive, toggleActive } = useMegaMenu()
  const navClasses = cx(s.navbar, className)

  return (
    <header className={s.header}>
      <nav className={navClasses}>
        <Brand
          href="/"
          className={s.logo} />


        <MobileMenu />

        <div ref={menuRef} className={s.desktop_nav}>
          <nav className={s.main}>
            <NavBar horizontal className={s.left_menu}>
              {
                primaryMenuOptions.map(menu => {
                  const { label, id } = menu

                  return (
                    <NavItem key={id}>
                      <ButtonLink
                        as={Button}
                        active={isActive(id)}
                        onClick={(evt: React.UIEvent) => {
                          toggleActive(id, evt.target)
                        }}>
                        {label}
                      </ButtonLink>
                    </NavItem>
                  )
                })
              }
            </NavBar>

            <NavBar horizontal>
              {
                secondaryMenuOptions.map(({ label, href, prefetch }, index) => {

                  if (!href) return false

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <NavItem key={index}>
                      <NavLink
                        prefetch={prefetch}
                        href={href}>
                        {label}
                      </NavLink>
                    </NavItem>
                  )
                })
              }
              <DownloadLauncherButton />
              <NavItem>
                <AccountMenu
                  menuItemsClassName={accountMenuItemsClassName} />
              </NavItem>
            </NavBar>

          </nav>

          <MegaMenu className={megaMenuClassName}>
            {
              (active) => {
                const menuOptions = primaryMenuOptions.find((menu) => menu.id === active)

                if (!menuOptions) return false

                const { image, options } = menuOptions

                return (
                  <MegaMenuPanel image={image} options={options} />
                )
              }
            }
          </MegaMenu>
        </div>
      </nav>
    </header >
  )
}

export default Header