import { ReactNode } from 'react'

export enum Views {
    Cols3 = 'cols3',
    Cols2 = 'cols2',
    List = 'list',
}

export type GridListProps = {
    show3cols?: boolean
    show2cols?: boolean
    showList?: boolean
    view?: Views
    children?: ReactNode | ((view?: string) => ReactNode)
    controls?: ReactNode | ((controls: ReactNode) => ReactNode)
}
