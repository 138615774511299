import { API_AZURE_MARKETPLACE_GET_SALE_OVERVIEW_STATS } from 'consts/apiRoutes'
import { AzureMarketplaceSaleOverviewStatsResponse } from '@/types/marketplace'

import HttpClientBase from './HttpClientBase'

class MarketplaceHttpClient extends HttpClientBase {
    static setAccessToken(accessToken: string) {
        super.setAccessToken(accessToken)

        return this
    }

    static async GetSaleOverviewStats(urlRoute: string) {
        return this.request<AzureMarketplaceSaleOverviewStatsResponse>({
            url: API_AZURE_MARKETPLACE_GET_SALE_OVERVIEW_STATS,
            method: 'POST',
            data: {
                UrlRoute: urlRoute,
            },
        })
    }
}

export default MarketplaceHttpClient
