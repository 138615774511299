import { KoiosAddressAssetType } from './types'
import fetchAssets from './fetchAssets'

const MAX_ROWS_PER_REQUEST = 500 // Set the maximum number of rows per API request

const fetchAllAssets = async (addresses: string[]): Promise<KoiosAddressAssetType[]> => {
    let allAssets = [] as KoiosAddressAssetType[]
    let offset = 0
    let moreData = true

    while (moreData) {
        const data = await fetchAssets(addresses, offset, MAX_ROWS_PER_REQUEST)
        allAssets = allAssets.concat(data)

        if (data.length < MAX_ROWS_PER_REQUEST) {
            moreData = false // No more data to fetch
        } else {
            offset += MAX_ROWS_PER_REQUEST
        }
    }

    return allAssets
}

export default fetchAllAssets
