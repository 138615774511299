import { useCallback } from 'react'
import { DecodedValueMap, useQueryParams } from 'use-query-params'
import {
    UseFilterFilterValues,
    UseFiltersFilterConfiguration,
    UseFiltersFilteredValues,
} from './types'

/**
 * Creates a consistent format for values
 * @param value
 */
export const formatFilterValue = (value: string | number): string =>
    value ? value.toString().toLowerCase() : ''

// Create config for query params hook using filter list
const createConfig = (config: UseFiltersFilterConfiguration) =>
    config.reduce(
        (data, filter) => ({
            ...data,
            // Note: we are using an alias and not the id, to allow us to customize the url parameter
            [filter.alias]: filter.type,
        }),
        {},
    )

/**
 * Provides a selection of helpers for creating checkbox filters that use query parameters to enable refreshing
 * @param config
 * @returns
 */
function useFilters(config: UseFiltersFilterConfiguration) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [query, setQuery] = useQueryParams<DecodedValueMap<any>>(createConfig(config))

    /**
     * Retrieve a single filter configuration
     * @param alias
     */
    const getFilterConfig = useCallback(
        (alias: string) => config.find((filterOption) => filterOption.alias === alias),
        [config],
    )

    const clearFilters = useCallback(
        (filterKeys?: string | string[]) => {
            if (!filterKeys) return setQuery({}, 'replace')

            if (Array.isArray(filterKeys)) {
                const cleared = {} as Record<string, undefined>

                filterKeys.forEach((key) => {
                    cleared[key] = undefined
                })

                return setQuery(cleared)
            }

            setQuery({ [filterKeys]: undefined })
        },
        [setQuery],
    )

    /**
     * Filter a list of filters and values using data
     * @param data
     * @param initialFilters
     */
    const getExistingFilterValues = useCallback(
        (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data: Array<Record<string, any>> = [],
            filters: UseFilterFilterValues = {},
            hideEmptyFilters = true,
        ) => {
            const filteredValues = {} as UseFiltersFilteredValues
            const hasData = data && data.length > 0

            // Iterate over the keys of the filters object
            Object.keys(filters).forEach((key) => {
                const filterConfig = config.find((item) => (item.alias || item.id) === key)

                if (filterConfig) {
                    const { id, alias } = filterConfig
                    // Filter out values that do not exist in the assets array
                    const values = !hideEmptyFilters
                        ? filters[key]
                        : filters[key].filter((value) =>
                              !hasData
                                  ? true
                                  : data.some(
                                        (item) => item[id] === value || item[alias] === value,
                                    ),
                          )

                    filteredValues[key] = values.map((value) => {
                        const filterValue = typeof value === 'object' ? value.value : value
                        const filterDisplayName =
                            typeof value === 'object' ? value.displayName : value

                        return {
                            displayName: filterDisplayName,
                            value: filterValue,
                            count: !hasData
                                ? 0
                                : data.reduce(
                                      (count, item) =>
                                          count +
                                          (item[id] === filterValue || item[alias] === filterValue
                                              ? 1
                                              : 0),
                                      0,
                                  ),
                        }
                    })
                }
            })

            return filteredValues
        },
        [config],
    )

    /**
     * Returns an object with an entry for each filter and a list of active filters
     */
    const getFilterBy = useCallback(
        () =>
            config.reduce((result, filter) => {
                const { alias, id } = filter
                const filterValues = query[alias]

                if (filterValues) {
                    return {
                        ...result,
                        [id]: filterValues,
                    }
                }

                return result
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }, {} as Record<string, any>),
        [config, query],
    )

    /**
     * Returns whether a filter is active
     * @param filter
     * @param value
     */
    const isActive = useCallback(
        (filters: string[] | string, value: string | number, caseSensitive = false) => {
            if (Array.isArray(filters))
                return filters?.some((v) => {
                    if (caseSensitive) return v === value.toString()

                    return v.toLowerCase() === value.toString().toLowerCase()
                })

            if (caseSensitive) return filters === value.toString()

            return filters ? filters.toLowerCase() === value.toString().toLowerCase() : false
        },
        [],
    )

    /**
     * Creates an onChange event for checkboxes
     * @param filter
     * @param value
     */
    const onFilterChange = useCallback(
        (filter: string, value: string | number, resetFilter?: string) => {
            const filterConfig = getFilterConfig(filter)
            const multiple = filterConfig?.multiple === undefined ? true : filterConfig?.multiple
            const filterValues = query[filter] as string[]
            const valueToCompare = filterConfig?.caseSensitive ? value : formatFilterValue(value)
            const active = isActive(filterValues, value, filterConfig?.caseSensitive)

            // Use to reset page or offset
            const filterToReset = resetFilter ? { [resetFilter]: undefined } : {}

            if (multiple) {
                if (active) {
                    setQuery({
                        [filter]: filterValues.filter(
                            (selectedValue) => selectedValue !== valueToCompare,
                        ),
                        ...filterToReset,
                    })

                    return
                }

                setQuery({
                    [filter]: [...filterValues, valueToCompare],
                    ...filterToReset,
                })

                return
            }

            if (active && !filterConfig?.required) {
                setQuery({
                    [filter]: undefined,
                    ...filterToReset,
                })

                return
            }

            setQuery({
                [filter]: [valueToCompare],
                ...filterToReset,
            })
        },
        [query, setQuery, isActive, getFilterConfig],
    )

    /**
     * Returns whether a filter is active
     * @param filter
     * @param value
     */
    const isFilterActive = useCallback(
        (filter: string, value: string | number) => {
            const filterValues = query[filter] as string[] | string

            return isActive(filterValues, value)
        },
        [query, isActive],
    )

    return {
        onFilterChange,
        getExistingFilterValues,
        getFilterConfig,
        isFilterActive,
        formatFilterValue,
        getFilterBy,
        clearFilters,
        setQuery,
        query,
    }
}

export default useFilters
