import { useState, useEffect, useCallback } from 'react'

import { PlayerHttpClient } from '@/network/httpClients'
import { NftMetadataSearchRequestType, WalletAssetType } from 'types/Api'
import { createAssetWithMetadata } from '@/utils/Player'
import { WalletApi } from '@cardano-sdk/dapp-connector'

import useLocalStorage from '../../useLocalStorage'

import { HumanReadableUtxoAsset, NetworkType } from './global/types'
import {
    awaitTimeout,
    decodePaymentHexAddress,
    decodeStakeHexAddress,
    EnablementFailedError,
    encodeBechAddress,
    FailedToReadWalletError,
    InjectWalletListener,
    Observable,
    Utxo,
    WalletExtensionNotFoundError,
    WalletNotCip30CompatibleError,
    WrongNetworkTypeError,
} from './utils'
import { CIPWalletError, ISignMessageFunc, ISignTxFunc, UseCardanoProps } from './types'

const enabledObserver = new Observable<boolean>(false)
const isConnectingObserver = new Observable<boolean>(false)
const enabledWalletObserver = new Observable<string | null>('')
const stakeAddressObserver = new Observable<string | null>(null)
const installedWalletExtensionsObserver = new Observable<Array<string>>([])

function useCardano(props?: UseCardanoProps) {
    const { limitNetwork, autoConnect = false, debugId = '' } = props || {}
    const [isConnected, setIsConnected] = useLocalStorage<boolean>('cf-wallet-connected', false)
    const [lastConnectedWallet, setLastConnectedWallet] = useLocalStorage<string>(
        'cf-last-connected-wallet',
        '',
    )
    const [isEnabled, setIsEnabled] = useState<boolean>(enabledObserver.get())
    const [isConnecting, setIsConnecting] = useState<boolean>(isConnectingObserver.get())
    const [enabledWallet, setEnabledWallet] = useState<string | null>(enabledWalletObserver.get())
    const [stakeAddress, setStakeAddress] = useState<string | null>(stakeAddressObserver.get())
    const [installedExtensions, setInstalledExtensions] = useState<Array<string>>(
        installedWalletExtensionsObserver.get(),
    )

    useEffect(() => {
        enabledObserver.subscribe(setIsEnabled)
        isConnectingObserver.subscribe(setIsConnecting)
        enabledWalletObserver.subscribe(setEnabledWallet)
        stakeAddressObserver.subscribe(setStakeAddress)
        installedWalletExtensionsObserver.subscribe(setInstalledExtensions)

        return () => {
            enabledObserver.unsubscribe(setIsEnabled)
            isConnectingObserver.unsubscribe(setIsConnecting)
            enabledWalletObserver.unsubscribe(setEnabledWallet)
            stakeAddressObserver.unsubscribe(setStakeAddress)
            installedWalletExtensionsObserver.unsubscribe(setInstalledExtensions)
        }
    }, [])

    const disconnect = useCallback(
        /**
         * Disconnect connected wallet
         */
        () => {
            setIsConnected(false)
            window.dispatchEvent(new Event('storage'))
            enabledObserver.set(false)
            enabledWalletObserver.set(null)
            stakeAddressObserver.set(null)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const connectToWallet = useCallback(
        /**
         * Connect to supported browser wallets by name
         * @param walletName
         * @returns
         */
        async (walletName: string) => {
            const { cardano } = window

            if (typeof cardano === 'undefined') {
                return
            }

            if (typeof cardano[walletName].isEnabled === 'function') {
                const api = await cardano[walletName].enable()

                if (typeof api.getRewardAddresses === 'function') {
                    // If a wallet does not connect timeout the promise. Lace wallet would often hang if you didn't interact with browser wallet first.
                    // Yoroi wasn't a fan of sending the getRewardAddresses function reference so created an async function
                    const hexAddresses = await awaitTimeout<string[]>(async () => {
                        const result = await api.getRewardAddresses()

                        return result
                    }, 30).catch(() => {
                        throw new FailedToReadWalletError(walletName)
                    })

                    if (hexAddresses && hexAddresses.length > 0) {
                        const bech32Address = decodeStakeHexAddress(hexAddresses[0])

                        let networkType = NetworkType.MAINNET
                        if (bech32Address.startsWith('stake_test')) {
                            networkType = NetworkType.TESTNET
                        }

                        if (limitNetwork && limitNetwork !== networkType) {
                            throw new WrongNetworkTypeError(limitNetwork, networkType)
                        }

                        stakeAddressObserver.set(bech32Address)
                        enabledWalletObserver.set(walletName)
                        enabledObserver.set(true)

                        if (walletName === 'typhoncip30') {
                            setLastConnectedWallet('typhon')
                        } else {
                            setLastConnectedWallet(walletName)
                        }

                        window.dispatchEvent(new Event('storage'))
                    }
                } else {
                    throw new WalletNotCip30CompatibleError(walletName)
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [limitNetwork, enabledWallet, stakeAddress, isEnabled],
    )

    const canUseNetwork = useCallback(
        /**
         * Check whether wallet is allowed according to parameters set
         * @param onError
         * @returns
         */
        (onError?: (error: Error) => void) => {
            let networkType = NetworkType.MAINNET
            if (stakeAddress && stakeAddress.startsWith('stake_test')) {
                networkType = NetworkType.TESTNET
            }

            if (limitNetwork && limitNetwork !== networkType) {
                const error = new WrongNetworkTypeError(limitNetwork, networkType)
                if (typeof onError === 'function') {
                    onError(error)
                } else {
                    // eslint-disable-next-line no-console
                    console.warn(error)
                }
            }

            return true
        },
        [stakeAddress, limitNetwork],
    )

    const getWalletApi = useCallback(
        /**
         * Retrun Wallet API for specified browser wallet
         * @param wallet
         * @returns
         */
        async (wallet: string): Promise<WalletApi> => {
            const { cardano } = window

            const api = await cardano[wallet === 'typhon' ? 'typhoncip30' : wallet].enable()

            // @ts-ignore: TS conflict
            return api
        },
        [],
    )

    const getUtxos = useCallback(
        /**
         * Get enabled wallet UTXOs
         * @returns
         */
        async () => {
            if (!isEnabled || !enabledWallet) {
                return
            }

            const api = await getWalletApi(enabledWallet)

            if (typeof api.getUtxos === 'function') {
                if (!canUseNetwork()) return

                const utxos = await api.getUtxos()

                return utxos
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEnabled, enabledWallet, limitNetwork],
    )

    const getHumanReadableUtxos = useCallback(
        /**
         * Returns array of UTXO classes. The UTXO class contains a readable version of the utxo
         * @returns
         */
        async () => {
            const utxos = await getUtxos()

            return utxos?.map((utxo) => new Utxo(utxo))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEnabled, enabledWallet, limitNetwork],
    )

    const signMessage: ISignMessageFunc = useCallback(
        /**
         *
         * Sign Data. Uses Stake address by default.
         * @param message
         * @param bechAddress
         * @param onSignMessage
         * @param onSignError
         * @returns
         */
        async (
            message: string,
            bechAddress?: string,
            onSignMessage?: (signature: string, key: string | undefined) => void,
            onSignError?: (error: Error) => void,
        ) => {
            if (!isEnabled || !enabledWallet) {
                return
            }

            const api = await getWalletApi(enabledWallet)
            let cardanoHexAddress = bechAddress ? encodeBechAddress(bechAddress) : null

            if (!bechAddress && typeof api.getRewardAddresses === 'function') {
                const hexAddresses = await api.getRewardAddresses()

                if (hexAddresses.length > 0) {
                    ;[cardanoHexAddress] = hexAddresses
                }
            }

            if (!canUseNetwork(onSignError) || !cardanoHexAddress) return

            let hexMessage = ''

            for (let i = 0, l = message.length; i < l; i += 1) {
                hexMessage += message.charCodeAt(i).toString(16)
            }

            try {
                const dataSignature = await api.signData(cardanoHexAddress, hexMessage)

                if (typeof onSignMessage === 'function') {
                    const { signature, key } = dataSignature
                    onSignMessage(signature.toString(), key.toString())
                }
            } catch (error) {
                if (typeof onSignError === 'function') {
                    onSignError(error as Error)
                } else {
                    // eslint-disable-next-line no-console
                    console.warn(error)
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEnabled, enabledWallet, limitNetwork],
    )

    const signTx: ISignTxFunc = useCallback(
        /**
         *
         * @param tx
         * @param partialSign
         * @param onSignMessage
         * @param onSignError
         * @returns
         */
        async (
            tx: string,
            partialSign = true,
            onSignTx?: (signature: string) => void,
            onSignError?: (error: Error) => void,
        ) => {
            // console.log('Sign TX', enabledWallet)

            if (!isEnabled || !enabledWallet) {
                return
            }

            const api = await getWalletApi(enabledWallet)

            try {
                const signature = await api.signTx(tx, partialSign)

                if (typeof onSignTx === 'function') {
                    onSignTx(signature.toString())
                }
            } catch (error) {
                if (typeof onSignError === 'function') {
                    onSignError(error as Error)
                } else {
                    // eslint-disable-next-line no-console
                    console.warn(error)
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEnabled, enabledWallet, limitNetwork],
    )

    const connect = useCallback(
        async (
            walletName: string,
            onConnect?: (walletName: string, stakeAddress?: string | null) => void | undefined,
            onError?: (code: Error) => void,
        ) => {
            if (isConnecting) return

            isConnectingObserver.set(true)
            const { cardano } = window
            let walletKey = walletName.toLowerCase()

            if (typeof cardano !== 'undefined') {
                if (typeof cardano[walletKey] !== 'undefined') {
                    try {
                        if (walletKey === 'typhon') {
                            walletKey = 'typhoncip30'
                        }

                        await connectToWallet(walletKey)

                        setIsConnected(true)
                        window.dispatchEvent(new Event('storage'))

                        if (typeof onConnect === 'function') {
                            onConnect(walletKey, stakeAddress)
                        }

                        isConnectingObserver.set(false)
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.log(error)
                        isConnectingObserver.set(false)

                        if (typeof onError === 'function') {
                            if (
                                error instanceof WalletNotCip30CompatibleError ||
                                error instanceof WrongNetworkTypeError ||
                                error instanceof FailedToReadWalletError
                            ) {
                                onError(error)
                            } else {
                                let reason

                                if (
                                    error &&
                                    typeof error === 'object' &&
                                    Object.hasOwn(error, 'code')
                                )
                                    reason = (error as CIPWalletError).info || 'User Rejected'
                                else reason = (error as Error).message || (error as string)

                                onError(new EnablementFailedError(walletKey, reason))
                            }
                        } else {
                            // eslint-disable-next-line no-console
                            console.error(error)
                        }
                    }
                } else {
                    isConnectingObserver.set(false)
                    if (typeof onError === 'function') {
                        onError(new WalletExtensionNotFoundError(walletKey))
                    }
                }
            } else {
                isConnectingObserver.set(false)
                if (typeof onError === 'function') {
                    onError(new WalletExtensionNotFoundError(walletKey))
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [connectToWallet, isConnecting],
    )

    const checkEnabled = useCallback(
        /**
         * Check if wallet is enabled
         * @returns
         */
        async () => {
            const { cardano } = window

            if (typeof cardano === 'undefined') {
                return
            }

            if (lastConnectedWallet && lastConnectedWallet !== '') {
                if (lastConnectedWallet === 'typhon') {
                    connect('typhoncip30')
                } else {
                    connect(lastConnectedWallet)
                    // console.log(debugId, 'checkEnabled', lastConnectedWallet)
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [lastConnectedWallet],
    )

    useEffect(() => {
        if (autoConnect && isConnected) {
            checkEnabled()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected])

    const getInstalledWalletExtensions: (supportedWallets?: Array<string>) => Array<string> = (
        supportedWallets,
    ) => {
        const { cardano } = window

        if (typeof cardano === 'undefined') {
            return []
        }

        if (supportedWallets) {
            return Object.keys(cardano)
                .map((walletExtension) => walletExtension.toLowerCase())
                .filter((walletExtension) =>
                    supportedWallets
                        .map((walletName) => walletName.toLowerCase())
                        .includes(walletExtension),
                )
        }

        return Object.keys(cardano)
            .filter((walletExtension) => typeof cardano[walletExtension].enable === 'function')
            .map((walletExtension) => walletExtension.toLowerCase())
    }

    useEffect(() => {
        const injectWalletListener = new InjectWalletListener(() => {
            installedWalletExtensionsObserver.set(getInstalledWalletExtensions())
        })
        injectWalletListener.start()

        return () => {
            injectWalletListener.stop()
        }
    }, [])

    const getAddresses = useCallback(
        async () => {
            if (!isEnabled || !enabledWallet) {
                return []
            }

            const api = await getWalletApi(enabledWallet)
            const unused = await api.getUnusedAddresses()
            const used = await api.getUsedAddresses()

            return [...used, ...unused]
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEnabled, enabledWallet, limitNetwork],
    )

    /**
     * Find addresses that contain accepted policy ids
     */
    const getAddressAssets = useCallback(
        async (policies: string[], limit?: 10): Promise<WalletAssetType[]> => {
            const utxos = await getHumanReadableUtxos()

            if (!utxos || utxos.length === 0) {
                return []
            }
            // Get all utxos with nft's
            const utxosWithAssets = utxos.filter((utxo) => {
                const assets = utxo.findAssetsWithPolicyIds(policies) || []

                return assets.length > 0
            }, [] as HumanReadableUtxoAsset[])

            if (utxosWithAssets.length === 0) {
                return []
            }

            // Get all assets in utxos
            const assetMetadataSearchPayload = utxosWithAssets.reduce((data, utxo) => {
                if (utxo.utxo === null) return data

                const utxoAssets = utxo.findAssetsWithPolicyIds(policies) || []

                if (utxoAssets.length > 0) {
                    const payload = utxoAssets.flatMap(({ assets, policyId }) =>
                        assets.map((asset) => ({
                            policyId,
                            assetName: asset.assetNameHex,
                        })),
                    )

                    return [...data, ...payload]
                }

                return data
            }, [] as NftMetadataSearchRequestType)

            // Get metadata, we only ever show 10 previews so cap this to 10.
            const { data: assetMetadata } = await PlayerHttpClient.GetUserWalletAssets(
                limit ? assetMetadataSearchPayload.slice(0, limit) : assetMetadataSearchPayload,
            )

            // TODO:: Do we need an error handler here? this will work fine without the extra data.
            // Parse metadata into assets and create a list of wallets / assets to display
            const allAssets: WalletAssetType[] = []

            utxosWithAssets.forEach((utxo) => {
                if (utxo.utxo === null) return

                const utxosWithSpecifiedAssets = utxo.findAssetsWithPolicyIds(
                    policies,
                ) as HumanReadableUtxoAsset[]

                if (utxosWithSpecifiedAssets.length > 0) {
                    const { tx } = utxo.utxo

                    // Merge metadata with assets data
                    utxosWithSpecifiedAssets.forEach((utxoWithSpecifiedAssets) => {
                        const { assets } = utxoWithSpecifiedAssets

                        assets.forEach((asset) => {
                            const assetHydated = createAssetWithMetadata(
                                utxoWithSpecifiedAssets.policyId,
                                asset.assetNameHex,
                                assetMetadata || [],
                                asset.quantity,
                                tx.txHash.toString(),
                            )

                            allAssets.push(assetHydated)
                        })
                    })
                }
            })

            return allAssets
        },
        [getHumanReadableUtxos],
    )

    /**
     * Get first usable address in a wallet
     * @returns Promise<string | undefined>
     */
    const getFirstAddress = useCallback(async (): Promise<string | undefined> => {
        const addresses = await getAddresses()

        if (addresses.length === 0) return undefined

        const address = addresses[0]
        const decodedPaymentHexAddress = decodePaymentHexAddress(address)

        return decodedPaymentHexAddress
    }, [getAddresses])

    /**
     * Find addresses that contain accepted policy ids
     */
    const getAddressesWithAssets = useCallback(
        async (policies: string[]): Promise<string[]> => {
            const utxos = await getHumanReadableUtxos()
            const firstAddress = await getFirstAddress()

            // Return the first usable address or none
            if (!utxos || utxos.length === 0) {
                return firstAddress ? [firstAddress] : []
            }

            // Get all utxos with nft's
            const utxosWithAssets = utxos.filter((utxo) => {
                const assets = utxo.findAssetsWithPolicyIds(policies) || []

                return assets.length > 0
            }, [] as HumanReadableUtxoAsset[])

            // Return the first usable address or none
            if (utxosWithAssets.length === 0) {
                return firstAddress ? [firstAddress] : []
            }

            // Parse metadata into assets and create a list of wallets / assets to display
            const addresses: string[] = []

            utxosWithAssets.forEach((utxo) => {
                if (utxo.utxo === null) return

                const utxosWithSpecifiedAssets = utxo.findAssetsWithPolicyIds(
                    policies,
                ) as HumanReadableUtxoAsset[]

                if (utxosWithSpecifiedAssets.length > 0) {
                    const { txDetails } = utxo.utxo
                    const { destination } = txDetails
                    const decodedPaymentHexAddress = decodePaymentHexAddress(destination)

                    if (!addresses.includes(decodedPaymentHexAddress))
                        addresses.push(decodedPaymentHexAddress)
                }
            }, [])

            return addresses
        },
        [getHumanReadableUtxos, getFirstAddress],
    )

    /**
     * Get enabled wallet stake address
     * @returns string
     */
    const getStakeAddress = async (): Promise<string | undefined> => {
        const { cardano } = window

        if (typeof cardano === 'undefined') {
            return undefined
        }

        if (!isEnabled || !enabledWallet) {
            return undefined
        }

        return stakeAddress as string
    }

    /**
     * Check if a wallet is enabled
     * @param walletName string
     * @returns boolean
     */
    const isWalletInstalled = (walletName: string): boolean => {
        const { cardano } = window

        if (typeof cardano === 'undefined') {
            return false
        }

        return Object.keys(cardano).includes(walletName)
    }

    return {
        isEnabled,
        isConnected,
        isConnecting,
        enabledWallet,
        stakeAddress,
        signMessage,
        signTx,
        getUtxos,
        getHumanReadableUtxos,
        getWalletApi,
        connect,
        disconnect,
        installedExtensions,
        getAddresses,
        getAddressAssets,
        getAddressesWithAssets,
        isWalletInstalled,
        getStakeAddress,
    }
}

export default useCardano
