import React from 'react'

import { NavItemProps } from './types'

/**
 * Nav Item wrapper
 * @param props 
 * @returns 
 */
const NavItem = (props: NavItemProps) => {
  const { children, className } = props;

  return (
    <li className={className}>{children}</li>
  )
}

export default NavItem