import React, { useState } from 'react'
import { signOut } from 'next-auth/react'

import { PLAYER_ACCOUNT_MENU_OPTIONS, PRIMARY_MENU_OPTIONS, SECONDARY_MENU_OPTIONS } from 'consts/navigation'

import { ROUTE_LOGIN, ROUTE_REGISTER } from 'consts/routes'
import { useAuth } from '@/providers/AuthenticationProvider'
// ESLint didn't like the name "Link", so required to import under different name
import NavLink from '@/components/ui/Navigation/NavLink'
import { MobileMenuButton, NavBar, NavItem } from '@/components/ui/Navigation'
import { Button, Typography } from '@/components/ui'
import { Gate } from '@/network/auth/gate'
import { t } from 'helpers/translation'

import { NavigationItems, NavigationPrimaryMenuItems } from 'types/navigation'
import s from './styles.module.css'

/**
 * MobileMenu navigation
 * @returns 
 */
const MobileMenu = () => {
  const { user, isLoggedIn } = useAuth()
  const [visible, setVisible] = useState<boolean>(false)
  const playerMenuOptions: NavigationItems = PLAYER_ACCOUNT_MENU_OPTIONS
  const primaryMenuOptions: NavigationPrimaryMenuItems = PRIMARY_MENU_OPTIONS
  const secondaryMenuOptions: NavigationItems = SECONDARY_MENU_OPTIONS
  const close = () => setVisible(false)

  return (
    <>
      <div className={s.mobile_button}>
        <MobileMenuButton active={visible} onClick={() => setVisible(!visible)} />
      </div>
      {visible &&
        <nav className={s.main}>
          <NavBar horizontal>
            <div>
              {
                isLoggedIn ?
                  <>
                    <NavItem>
                      <Typography className={s.menu_parent}>
                        <Typography uppercase>Logged in as:</Typography> {user?.displayName}
                      </Typography>
                    </NavItem>
                    <div className={s.submenu}>
                      {
                        playerMenuOptions.map(({ label, href, subMenu, permissions }) => {

                          const hasPermission = permissions ? Gate(user).anyRoles(permissions) : true

                          if (!hasPermission) return false

                          if (href)
                            return (
                              <NavItem key={href}>
                                <NavLink onClick={close} href={href}>{label}</NavLink>
                              </NavItem>
                            )

                          if (subMenu) {
                            return subMenu.map(({ label: subMenuItemLabel, href: subMenuItemHref, permissions: subMenuItemPermissions }) => {

                              const canView = subMenuItemPermissions ? Gate(user).anyRoles(subMenuItemPermissions) : true

                              if (!canView) return false

                              return (
                                <NavItem key={href}>
                                  <NavLink
                                    onClick={close}
                                    href={subMenuItemHref}>{subMenuItemLabel}</NavLink>
                                </NavItem>
                              )
                            })
                          }

                          return <></>

                        })
                      }
                      <NavItem>
                        <Button
                          className={s.logout}
                          onClick={() => signOut({ callbackUrl: '/' })}>
                          {t("navigation.menus.logout")}
                        </Button>
                      </NavItem>
                    </div>
                  </>
                  :
                  <>
                    <NavItem>
                      <NavLink onClick={close} href={ROUTE_LOGIN}>
                        {t("navigation.menus.account")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink onClick={close} href={ROUTE_REGISTER}>
                        {t("navigation.menus.register")}
                      </NavLink>
                    </NavItem>
                  </>
              }
            </div>
            {
              primaryMenuOptions.map(({ label, options }) => (
                <NavItem key={label}>
                  <Typography
                    className={s.menu_parent}
                    uppercase>
                    {label}
                  </Typography>
                  <ul className={s.submenu}>
                    {
                      options.map(({ label: optionLabel, href }) => (
                        <NavItem key={href}>
                          <NavLink href={href}>{optionLabel}</NavLink>
                        </NavItem>
                      ))
                    }
                  </ul>
                </NavItem>
              ))
            }

            {
              secondaryMenuOptions.map(({ label, href }) => {

                if (!href) return false

                return (
                  <NavItem key={href}>
                    <NavLink onClick={close} href={href}>{label}</NavLink>
                  </NavItem>
                )
              })
            }
          </NavBar>

        </nav>
      }
    </>
  )
}

export default MobileMenu