import React, { useMemo, useState } from 'react'
import cn from 'classnames'

import Button from '../Button'
import VisuallyHidden from '../VisuallyHidden'
import { Gridx2, Gridx3, List } from '../Icon'
import Box from '../Box'

import { GridListProps, Views } from './types'

import s from './styles.module.css'

export const GRID_2X_VIEW_TITLE = 'Change view to 2 columns'
export const GRID_3X_VIEW_TITLE = 'Change view to 3 columns'
export const GRID_LIST_VIEW_TITLE = 'Change view to a list'

const GridList = (props: GridListProps) => {
  const { children, controls, show3cols = true, show2cols = true, showList = true, view } = props
  const [currentView, setView] = useState<Views | undefined>(view)

  const gridClasses = cn(
    s.list,
    currentView === Views.Cols2 && s.grid_cols_2,
    currentView === Views.Cols3 && s.grid_cols_3,
    currentView === Views.List && s.grid_cols_1,
  )

  const onViewChange = (state: Views) => {
    setView(state)
  }

  const viewControls = useMemo(() => (
    <Box display="flex">
      {
        show2cols &&
        <Button
          variant={currentView === Views.Cols2 ? "primary" : "default"}
          onClick={() => onViewChange(Views.Cols2)}>
          <Gridx2 />
          <VisuallyHidden>{GRID_2X_VIEW_TITLE}</VisuallyHidden>
        </Button>
      }
      {
        show3cols &&
        <Button
          variant={currentView === Views.Cols3 ? "primary" : "default"}
          onClick={() => onViewChange(Views.Cols3)}>
          <Gridx3 />
          <VisuallyHidden>{GRID_3X_VIEW_TITLE}</VisuallyHidden>
        </Button>
      }
      {
        showList &&
        <Button
          variant={currentView === Views.List ? "primary" : "default"}
          onClick={() => onViewChange(Views.List)}>
          <List />
          <VisuallyHidden>{GRID_LIST_VIEW_TITLE}</VisuallyHidden>
        </Button>
      }
    </Box>
  ), [currentView, show2cols, show3cols, showList])

  const showComponentControls = showList || show2cols || show3cols

  return (
    <>
      {
        showComponentControls || controls &&
        <div className={s.controls}>
          {
            controls && typeof controls === "function" ?
              controls(viewControls)
              :
              <>
                {controls}
                {viewControls}
              </>
          }
        </div>
      }
      <div className={gridClasses}>
        {
          children && typeof children === "function" ? children(currentView) : children
        }
      </div>
    </>
  )
}

export default GridList
