import React, { useState } from 'react'

import { Input, ValidationSummary } from '@/components/ui/Form'
import { Button, Modal, Typography } from '@/components/ui'
import t from 'helpers/translation/getTranslation'

import { PasswordConfirmModalProps } from './types'
import s from './styles.module.css'

const PasswordConfirmModal = (props: PasswordConfirmModalProps) => {
  const { onSubmit, onCancel, open, error, title, message, loading } = props
  const [password, setPassword] = useState<string>("")

  const handleSubmit = () => {
    if (onSubmit) onSubmit(password)

    setPassword("")
  }

  const handleCancel = () => {
    setPassword("")

    if (onCancel) onCancel()
  }

  return (
    <>
      <Modal
        size="md"
        open={open}
        showCloseButton={false}
        canCloseOnClickOutside={false}>
        <>
          <Typography
            as="h3"
            className={s.popup_message}>
            {title}
          </Typography>
          <Typography
            as="p"
            className={s.popup_message}>
            {message}
          </Typography>
          { /* First input is a hack to stop Google autofilling */}
          <Input
            tabIndex={-1}
            className={s.popup_hidden_input}
            autoComplete="off"
            name="password"
            type="password" />
          <Input
            onChange={(evt) => { setPassword(evt.currentTarget.value) }}
            value={password}
            autoComplete="new_password"
            name="password"
            type="password" />
          <ValidationSummary
            compact
            className={s.popup_error}
            errors={error ? { root: { message: error } } : {}} />
          <div className={s.popup_actions}>
            <Button
              onClick={handleSubmit}
              isLoading={loading}
              filled
              variant="primary">
              {t("components.passwordConfirmModal.submit")}
            </Button>
            <Button
              onClick={handleCancel}
              variant="default"
              outline>
              {t("components.passwordConfirmModal.cancel")}
            </Button>
          </div>
        </>
      </Modal>
    </>
  )
}

export default PasswordConfirmModal